import PublishIcon from "@mui/icons-material/Publish";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCourseMutation } from "../../../API";
import { withCourse } from "../../../components/withCourse";
import WizardPageTitle from "../../../components/WizardPageTitle";
import { Course } from "../../../graphql/API";
import { exportCourseToCanvas } from "./exportCourseToCanvas";

function ReviewAndExport({ course }: { course: Course }) {
  const { id } = course;
  const navigate = useNavigate();
  const updateCourseMutation = useCourseMutation(id);
  const [isExporting, setIsExporting] = useState(false);

  return (
    <Box>
      <WizardPageTitle title="Review and Export" />
      <Typography variant="body1" gutterBottom mb={4}>
        Please review your course here before exporting to Canvas. You can go
        back to any of the previous steps to make changes.
      </Typography>
      <Card variant="elevation" sx={{ p: 4 }}>
        <Typography variant="h3" gutterBottom>
          {course.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Course length: {course.lengthInWeeks} weeks <br />
          Course level: {course.courseLevel} <br />
          Student skill level: {course.studentSkillLevel} <br />
        </Typography>
        <Typography variant="h4" gutterBottom mt={2}>
          Course Objectives
        </Typography>
        {course.courseObjectives.map(
          ({ objectiveDescription, objectiveReasoning }, index) => (
            <Typography key={index} variant="body2">
              <strong>
                Objective {index + 1}: {objectiveDescription}
              </strong>
              <br />
              {objectiveReasoning}
              <br /> <br />
            </Typography>
          )
        )}
        <Typography variant="h4" gutterBottom mt={2}>
          Weekly Outline
        </Typography>
        {course.weeks.map((week, index) => {
          return (
            <div key={index}>
              <Typography variant="h6">
                Week {index + 1}: {week.weekTitle}
                <br />
              </Typography>
              <Typography variant="overline">
                {week.conceptsCoveredDuringTheWeek.join(", ")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {week.weekReasoning}
              </Typography>
              {course.quizzes
                .filter((quiz) => quiz.quizHeldInWeek === index + 1)
                .map((_, index) => {
                  return (
                    <Typography variant="body2" key={index}>
                      Learning Activities: <strong>Quiz</strong>
                    </Typography>
                  );
                })}
              {}
              <br />
            </div>
          );
        })}
        <Typography variant="h4" gutterBottom mt={2}>
          Objectives Covered by the Learning Activities
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Learning Activity</TableCell>
                {course.courseObjectives.map((_, index) => (
                  <TableCell key={index} align="center">
                    Objective {index + 1}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {course.quizzes.map((quiz, index) => (
                <TableRow
                  key={quiz.quizHeldInWeek}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Quiz {index + 1}
                  </TableCell>
                  {course.courseObjectives.map((objective, index) => {
                    // If quiz covers objective, return a checkmark
                    if (
                      quiz.courseObjectivesCoveredByTheQuiz.includes(
                        objective.objectiveDescription
                      )
                    ) {
                      return (
                        <TableCell key={index} align="center">
                          ✅
                        </TableCell>
                      );
                    }

                    // Otherwise, return an empty cell
                    return <TableCell key={index} align="center"></TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <LoadingButton
        variant="contained"
        color="success"
        startIcon={<PublishIcon />}
        onClick={async () => {
          setIsExporting(true);
          try {
            await exportCourseToCanvas(course);
            await updateCourseMutation.mutateAsync({
              id,
              hasBeenExportedToCanvas: true,
            });
            navigate("/");
          } catch (error) {
            console.error(error);
          } finally {
            setIsExporting(false);
          }
        }}
        loading={updateCourseMutation.isLoading || isExporting}
        sx={{ mt: 4 }}
      >
        Export to Canvas
      </LoadingButton>
    </Box>
  );
}

export default withCourse(ReviewAndExport);
