import ClearIcon from "@mui/icons-material/Clear";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { LoadingButton } from "@mui/lab";
import { Button, Grow, IconButton, Stack, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import {
  chatWithLanguageModel,
  LanguageModelFunction,
  LanguageModelMessage,
} from "../API";

interface AiAssistantButtonProps {
  conversationContext: LanguageModelMessage[];
  onAssistantResponse: Function;
  defaultPrompt?: string;
  placeholder?: string;
  functionsSchema?: LanguageModelFunction[];
}

function AiAssistantButton({
  conversationContext,
  onAssistantResponse,
  defaultPrompt = "",
  placeholder,
  functionsSchema,
}: AiAssistantButtonProps) {
  const [displayState, setDisplayState] = React.useState<
    "normal" | "enterMessage"
  >("normal");
  const [conversation, setConversation] = React.useState(conversationContext);
  const [prompt, setPrompt] = React.useState(defaultPrompt);

  const sendMessageMutation = useMutation({
    mutationFn: () => {
      return chatWithLanguageModel<any>(conversation, functionsSchema);
    },
    onSuccess: (data) => {
      setConversation([
        ...conversation,
        {
          role: "assistant",
          content: `${JSON.stringify(data.response)}`,
        },
      ]);
      onAssistantResponse(data.response);
    },
    onError: () => {
      // Remove the last message from the conversation
      setConversation(conversation.slice(0, conversation.length - 1));
    },
  });

  let element = <> </>;
  if (displayState === "normal") {
    element = (
      <Button
        variant="contained"
        startIcon={<TipsAndUpdatesIcon />}
        color="success"
        onClick={() => setDisplayState("enterMessage")}
      >
        AI Assist...
      </Button>
    );
  } else if (displayState === "enterMessage") {
    element = (
      <Grow in={true} key={displayState}>
        <Stack direction="row" spacing={2}>
          <TextField
            variant="outlined"
            size="small"
            placeholder={placeholder}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            sx={{ bgcolor: "white", width: "30em" }}
          ></TextField>
          <LoadingButton
            variant="contained"
            color="success"
            startIcon={<TipsAndUpdatesIcon />}
            onClick={async () => {
              setConversation([
                ...conversation,
                { role: "user", content: prompt },
              ]);
              await sendMessageMutation.mutateAsync();
              setDisplayState("normal");
            }}
            loading={sendMessageMutation.isLoading}
          >
            Chat
          </LoadingButton>
          <IconButton
            aria-label="cancel"
            onClick={() => {
              setDisplayState("normal");
            }}
          >
            <ClearIcon />
          </IconButton>
        </Stack>
      </Grow>
    );
  }

  return element;
}
export default AiAssistantButton;
