/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Progress
 */
export interface Progress {
  /**
   * percent completed
   * @type {number}
   * @memberof Progress
   */
  completion?: number;
  /**
   * the context owning the job.
   * @type {number}
   * @memberof Progress
   */
  contextId?: number;
  /**
   *
   * @type {string}
   * @memberof Progress
   */
  contextType?: string;
  /**
   * the time the job was created
   * @type {Date}
   * @memberof Progress
   */
  createdAt?: Date;
  /**
   * the ID of the Progress object
   * @type {number}
   * @memberof Progress
   */
  id?: number;
  /**
   * optional details about the job
   * @type {string}
   * @memberof Progress
   */
  message?: string;
  /**
   * optional results of the job. omitted when job is still pending
   * @type {object}
   * @memberof Progress
   */
  results?: object;
  /**
   * the type of operation
   * @type {string}
   * @memberof Progress
   */
  tag?: string;
  /**
   * the time the job was last updated
   * @type {Date}
   * @memberof Progress
   */
  updatedAt?: Date;
  /**
   * url where a progress update can be retrieved with an LTI access token
   * @type {string}
   * @memberof Progress
   */
  url?: string;
  /**
   * the id of the user who started the job
   * @type {number}
   * @memberof Progress
   */
  userId?: number;
  /**
   * the state of the job one of 'queued', 'running', 'completed', 'failed'
   * @type {string}
   * @memberof Progress
   */
  workflowState?: string;
}

/**
 * Check if a given object implements the Progress interface.
 */
export function instanceOfProgress(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ProgressFromJSON(json: any): Progress {
  return ProgressFromJSONTyped(json, false);
}

export function ProgressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Progress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completion: !exists(json, "completion") ? undefined : json["completion"],
    contextId: !exists(json, "context_id") ? undefined : json["context_id"],
    contextType: !exists(json, "context_type")
      ? undefined
      : json["context_type"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    id: !exists(json, "id") ? undefined : json["id"],
    message: !exists(json, "message") ? undefined : json["message"],
    results: !exists(json, "results") ? undefined : json["results"],
    tag: !exists(json, "tag") ? undefined : json["tag"],
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    url: !exists(json, "url") ? undefined : json["url"],
    userId: !exists(json, "user_id") ? undefined : json["user_id"],
    workflowState: !exists(json, "workflow_state")
      ? undefined
      : json["workflow_state"],
  };
}

export function ProgressToJSON(value?: Progress | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completion: value.completion,
    context_id: value.contextId,
    context_type: value.contextType,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    id: value.id,
    message: value.message,
    results: value.results,
    tag: value.tag,
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    url: value.url,
    user_id: value.userId,
    workflow_state: value.workflowState,
  };
}
