import { GraphQLQuery } from "@aws-amplify/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import Canvas from "./canvas-api";
import {
  Course,
  CreateCourseInput,
  CreateCourseMutation,
  DeleteCourseInput,
  DeleteCourseMutation,
  GetCourseQuery,
  ListCoursesQuery,
  UpdateCourseInput,
  UpdateCourseMutation,
} from "./graphql/API";
import * as mutations from "./graphql/mutations";
import { getCourse, listCourses } from "./graphql/queries";

// TODO : Proper error handling for all these
export async function createCanvasClient(): Promise<Canvas.DefaultApi> {
  const canvas_auth = localStorage.getItem("canvas_auth");
  if (!canvas_auth) {
    return Promise.reject("No canvas_auth found in localStorage");
  }
  const accessToken: string = JSON.parse(canvas_auth)?.access_token;
  if (!accessToken) {
    return Promise.reject("No access token found in canvas_auth local storage");
  }
  return new Canvas.DefaultApi(
    new Canvas.Configuration({
      // TODO: Read in the base path from an environment variable during build
      basePath: "https://canvas-dev.c3l.ai/api",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
}

export async function fetchCourses(): Promise<Course[]> {
  const courseData = await API.graphql<GraphQLQuery<ListCoursesQuery>>(
    graphqlOperation(listCourses)
  );
  const courses = courseData.data?.listCourses?.items;
  if (!courses) {
    return Promise.reject("No courses found");
  }
  return courses as Course[];
}

export async function fetchCourse(id: string): Promise<Course> {
  const courseData = await API.graphql<GraphQLQuery<GetCourseQuery>>(
    graphqlOperation(getCourse, { id })
  );
  const course = courseData.data?.getCourse as Course;
  if (!course) {
    return Promise.reject("No course found");
  }
  return course;
}

export type ApiResponseState = "OK" | "PENDING" | "ERROR";
export async function updateCourse(input: UpdateCourseInput): Promise<void> {
  const response = await API.graphql<GraphQLQuery<UpdateCourseMutation>>(
    graphqlOperation(mutations.updateCourse, { input })
  );
  if (response.errors) {
    console.error(response);
    return Promise.reject(response.errors);
  }
}

export async function createCourse(): Promise<Course> {
  const createCourseInput: CreateCourseInput = {
    id: uuidv4(),
		hasBeenExportedToCanvas: false,
    courseObjectives: [],
    topics: [],
    assessmentSettings: {},
    weeks: [],
    quizzes: [],
  };

  const response = await API.graphql<GraphQLQuery<CreateCourseMutation>>(
    graphqlOperation(mutations.createCourse, {
      input: createCourseInput,
    })
  );
  if (response.errors) {
    console.error(response);
    return Promise.reject(response.errors);
  }
  return await fetchCourse(createCourseInput.id as string);
}

export async function deleteCourse(id: string): Promise<void> {
  const deleteCourseInput: DeleteCourseInput = { id };
  await API.graphql<GraphQLQuery<DeleteCourseMutation>>(
    graphqlOperation(mutations.deleteCourse, { input: deleteCourseInput })
  );
}

export interface LanguageModelMessage {
  role: "system" | "user" | "assistant";
  content: string;
}
export interface LanguageModelFunction {
  name: string;
  description: string;
  parameters: Object;
}

export async function chatWithLanguageModel<T>(
  messages: LanguageModelMessage[],
  functions?: LanguageModelFunction[]
): Promise<T> {
  // Get the AWS Cognito token we're using for GraphQL auth
  const currentSession = await API.Auth.currentSession();
  const token = currentSession.getAccessToken().getJwtToken();

  const response = await fetch(
    process.env.REACT_APP_LAMBDA_FUNCTION_URL as string,
    {
      method: "POST",
      body: JSON.stringify({ messages, functions }),
      headers: {
        Authorization: token, // Use our cognito auth to access the lambda
      },
    }
  );
  return JSON.parse((await response.json()).message.function_call.arguments);

  //"https://u2i5zbnpkwe5ucrcpaq6nh5m2e0bdudr.lambda-url.ap-southeast-2.on.aws/"

  //const response = await API.post("LanguageModelApi", "/chat", {
  //body: {
  //messages,
  //functions,
  //},
  //});
  //return JSON.parse(response.message.function_call.arguments);
}

// React Query helpers
export function useCourseMutation(id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (input: UpdateCourseInput) => {
      return updateCourse(input);
    },
    onSuccess: () => {
      // If we've updated a course, we need to refresh all queries that depend on it
      queryClient.invalidateQueries({ queryKey: ["course", id] });
    },
  });
}

export function useCourseQuery(id: string) {
  return useQuery({
    queryKey: ["course", id],
    queryFn: async () => await fetchCourse(id),
    //onError: () =>
			//// DELETE ME Fix error with no quizzes
      //updateCourse({
        //id,
        //quizzes: [],
      //}),
  });
}
