import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactNode, useRef, useState } from "react";
import AiAssistantButton from "../../../components/AiAssistantButton";
import ConceptsCoveredChips from "../../../components/ConceptsCoveredChips";
import { Course, Quiz, QuizQuestion } from "../../../graphql/API";
import { getConversationContext, improveQuiz } from "./prompts";

interface QuizAccordionProps {
  value: Quiz;
  index: number;
  onUpdate: (value: Quiz) => Promise<void>;
  onDelete: () => void;
  isSelected: boolean;
  onAccordionChange: (expanded: boolean) => void;
  isLoading: boolean;
  course: Course;
}

export function QuizAccordion(props: QuizAccordionProps) {
  const { value, isSelected, onAccordionChange, isLoading, index, course } =
    props;
  const [cardState, setCardState] = useState<"displaying" | "editing">(
    "displaying"
  );
  // This tracks whether the according is "opening" or not (in the middle of it's animation)
  const [isTransitioning, setIsTransitioning] = useState(false);

  const [draftQuiz, setDraftQuiz] = React.useState<Quiz>(value);
  const accordionReference = useRef(null);

  return (
    <>
      <Box ref={accordionReference}>
        <Accordion
          variant="outlined"
          key={cardState}
          expanded={isSelected}
          sx={{ border: isSelected ? "3px solid lightblue" : undefined }}
          onChange={(_, expanded) => {
            setIsTransitioning(true);
            onAccordionChange(expanded);
          }}
          TransitionProps={{
            onEntered: () => {
              // Once the accordion has finished expanding, we can set the transition state to false
              // Then we can display the popper
              setIsTransitioning(false);
            },
            onEntering: (_, isAppearing) => {
              if (!isAppearing) {
                // On close we want to reset the card state back to displaying
                setCardState("displaying");
              }
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {cardState === "displaying" && (
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>
                    Quiz {index + 1}:{" "}
                    <em>Held in week {draftQuiz.quizHeldInWeek}</em>
                  </Typography>
                </Grid>
                <Grid item>
                  {draftQuiz.conceptsCoveredInTheQuiz.map((concept) => (
                    <Chip key={concept} label={concept} sx={{ ml: 1 }} />
                  ))}
                </Grid>
              </Grid>
            )}
            {cardState === "editing" && (
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>Quiz {index + 1}</Typography>
                </Grid>
              </Grid>
            )}
          </AccordionSummary>

          <Grow in={true}>
            {cardState === "displaying" ? (
              <AccordionDetails>
                <Typography variant="body1" gutterBottom>
                  <strong>Course Objectives Covered: </strong>
                </Typography>
                <List
                  dense
                  disablePadding
                  sx={{ listStyleType: "disc", pl: 4 }}
                >
                  {value.courseObjectivesCoveredByTheQuiz.map(
                    (objective, index) => (
                      <ListItem sx={{ display: "list-item" }} key={index}>
                        {objective}
                      </ListItem>
                    )
                  )}
                </List>
                <Typography variant="body1" gutterBottom pt={2}>
                  <strong>Questions</strong>
                </Typography>
                {value.quizQuestions.map((question, index) => (
                  <>
                    <Typography variant="body2" pt={2}>
                      Q{index + 1}: {question.question}
                    </Typography>

                    <List
                      dense
                      disablePadding
                      sx={{ listStyle: "disc", pl: 4 }}
                    >
                      {question.multipleChoiceAnswers.map(
                        (answer, answerIndex) => (
                          <ListItem
                            sx={{ display: "list-item" }}
                            key={answerIndex}
                          >
                            {answer}
                          </ListItem>
                        )
                      )}
                    </List>
                  </>
                ))}
              </AccordionDetails>
            ) : (
              // Editing
              <Stack spacing={2} p={3} alignItems="start">
                <TextField
                  id="outlined-basic"
                  fullWidth
                  type="number"
                  inputProps={{ min: 1, max: course.lengthInWeeks }}
                  label="Quiz held in week"
                  variant="outlined"
                  value={draftQuiz.quizHeldInWeek}
                  onChange={(event) => {
                    setDraftQuiz({
                      ...draftQuiz,
                      quizHeldInWeek: Number(event.target.value),
                    });
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel id="concepts-covered-label">
                    Concepts covered
                  </InputLabel>
                  <Select
                    labelId="concepts-covered-label"
                    label="Concepts covered"
                    multiple
                    value={draftQuiz.conceptsCoveredInTheQuiz}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      const newConcepts =
                        typeof value === "string" ? value.split(",") : value;
                      setDraftQuiz({
                        ...draftQuiz,
                        conceptsCoveredInTheQuiz: newConcepts,
                      });
                    }}
                    renderValue={(selected: string[]) => (
                      <ConceptsCoveredChips
                        values={selected}
                        onChange={(values) => {
                          setDraftQuiz({
                            ...draftQuiz,
                            conceptsCoveredInTheQuiz: values,
                          });
                        }}
                      />
                    )}
                  >
                    {course.weeks.reduce((menuItems, week, currentIndex) => {
                      menuItems.push(
                        <ListSubheader key={currentIndex}>
                          Week {currentIndex + 1}
                        </ListSubheader>
                      );
                      // Concepts already chosen
                      const alreadySelectedConceptsInOtherWeeks = course.quizzes
                        .filter((_, quizIndex) => quizIndex !== index) // Not this quiz
                        .map((quiz) => quiz.conceptsCoveredInTheQuiz)
                        .flat();

                      return menuItems.concat(
                        ...week.conceptsCoveredDuringTheWeek
                          .filter(
                            (concept) =>
                              !alreadySelectedConceptsInOtherWeeks.includes(
                                concept
                              )
                          )
                          .map((concept) => (
                            <MenuItem key={concept} value={concept}>
                              <Checkbox
                                checked={
                                  draftQuiz.conceptsCoveredInTheQuiz.indexOf(
                                    concept
                                  ) > -1
                                }
                              />
                              <ListItemText primary={concept} />
                            </MenuItem>
                          ))
                      );
                    }, [] as ReactNode[])}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="objectives-covered-label">
                    Course Objectives covered
                  </InputLabel>
                  <Select
                    labelId="objectives-covered-label"
                    label="Course Objectives covered"
                    multiple
                    value={draftQuiz.courseObjectivesCoveredByTheQuiz}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      const newObjectives =
                        typeof value === "string" ? value.split(",") : value;
                      setDraftQuiz({
                        ...draftQuiz,
                        courseObjectivesCoveredByTheQuiz: newObjectives.sort(),
                      });
                    }}
                    renderValue={(selected: string[]) => (
                      <ConceptsCoveredChips
                        values={selected}
                        onChange={(values) => {
                          setDraftQuiz({
                            ...draftQuiz,
                            courseObjectivesCoveredByTheQuiz: values.sort(),
                          });
                        }}
                      />
                    )}
                  >
                    {course.courseObjectives.map(
                      ({ objectiveDescription }, index) => {
                        const objective = objectiveDescription;
                        return (
                          <MenuItem key={index} value={objective}>
                            <Checkbox
                              checked={
                                draftQuiz.courseObjectivesCoveredByTheQuiz.indexOf(
                                  objective
                                ) > -1
                              }
                            />
                            <ListItemText primary={objective} />
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
                <Typography variant="body1" gutterBottom pt={2}>
                  <strong>Questions</strong>
                </Typography>
                {draftQuiz.quizQuestions.map((question, index) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <TextField
                        id="outlined-basic"
                        label={`Question ${index + 1}`}
                        variant="outlined"
                        value={question.question}
                        onChange={(event) => {
                          draftQuiz.quizQuestions[index].question =
                            event.target.value;
                          setDraftQuiz({
                            ...draftQuiz,
                          });
                        }}
                      />
                      <IconButton
                        color="error"
                        onClick={() => {
                          draftQuiz.quizQuestions.splice(index, 1);
                          setDraftQuiz({
                            ...draftQuiz,
                            quizQuestions: draftQuiz.quizQuestions,
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                    <TextField
                      id="outlined-basic"
                      multiline
                      fullWidth
                      label={`Question ${index + 1} Answers`}
                      variant="outlined"
                      value={question.multipleChoiceAnswers.join("\n")}
                      onChange={(event) => {
                        draftQuiz.quizQuestions[index].multipleChoiceAnswers =
                          event.target.value.split("\n");
                        setDraftQuiz({
                          ...draftQuiz,
                        });
                      }}
                    />
                  </>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  color="info"
                  onClick={() => {
                    setDraftQuiz({
                      ...draftQuiz,
                      quizQuestions: [
                        ...draftQuiz.quizQuestions,
                        {
                          question: "<New question>",
                          multipleChoiceAnswers: [
                            "<answer 1>",
                            "<answer 2>",
                            "<answer 3>",
                            "<answer 4>",
                          ],
                        } as QuizQuestion,
                      ],
                    });
                  }}
                >
                  Add Question
                </Button>

                <LoadingButton
                  variant="contained"
                  startIcon={<DoneIcon />}
                  loading={isLoading}
                  onClick={async () => {
                    await props.onUpdate(draftQuiz);
                    setCardState("displaying");
                  }}
                >
                  Save
                </LoadingButton>
              </Stack>
            )}
          </Grow>
        </Accordion>
      </Box>
      <Popper
        open={isSelected && !isTransitioning && cardState === "displaying"}
        anchorEl={accordionReference.current}
        placement="bottom-end"
      >
        <Card variant="elevation">
          <Grid container spacing={2} p={1}>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                color="info"
                onClick={() => {
                  setCardState("editing");
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <AiAssistantButton
                conversationContext={getConversationContext(
                  props.course
                ).concat([
                  {
                    role: "user",
                    content: `I'm currently editing this particular quiz, given here as a JSON object in triple quotes """${JSON.stringify(
                      value
                    )}"""`,
                  },
                  {
                    role: "assistant",
                    content: `Ok, how would you like to change this quiz?`,
                  },
                ])}
                functionsSchema={improveQuiz(course)}
                placeholder={"How would you like to change this quiz?"}
                onAssistantResponse={async (data: any) => {
                  await props.onUpdate(data);
                  setCardState("displaying");
                }}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                startIcon={<DeleteIcon />}
                color="error"
                loading={isLoading}
                onClick={() => {
                  props.onDelete();
                }}
              >
                Delete
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </Popper>
    </>
  );
}
