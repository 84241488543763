/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Enrollment } from "./Enrollment";
import {
  EnrollmentFromJSON,
  EnrollmentFromJSONTyped,
  EnrollmentToJSON,
} from "./Enrollment";

/**
 * A Canvas user, e.g. a student, teacher, administrator, observer, etc.
 * @export
 * @interface User
 */
export interface User {
  /**
   * Optional: If avatars are enabled and caller is admin, this field can be requested and will contain the current state of the user's avatar.
   * @type {string}
   * @memberof User
   */
  avatarState?: string;
  /**
   * If avatars are enabled, this field will be included and contain a url to retrieve the user's avatar.
   * @type {string}
   * @memberof User
   */
  avatarUrl?: string;
  /**
   * Optional: The user's bio.
   * @type {string}
   * @memberof User
   */
  bio?: string;
  /**
   * Optional: This field can be requested with certain API calls, and will return the users primary email address.
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   * Optional: This field can be requested with certain API calls, and will return a list of the users active enrollments. See the List enrollments API for more details about the format of these records.
   * @type {Array<Enrollment>}
   * @memberof User
   */
  enrollments?: Array<Enrollment>;
  /**
   * The first name of the user.
   * @type {string}
   * @memberof User
   */
  firstName?: string;
  /**
   * The ID of the user.
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   * The integration_id associated with the user.  This field is only included if the user came from a SIS import and has permissions to view SIS information.
   * @type {string}
   * @memberof User
   */
  integrationId?: string;
  /**
   * Optional: This field is only returned in certain API calls, and will return a timestamp representing the last time the user logged in to canvas.
   * @type {Date}
   * @memberof User
   */
  lastLogin?: Date;
  /**
   * The last name of the user.
   * @type {string}
   * @memberof User
   */
  lastName?: string;
  /**
   * Optional: This field can be requested with certain API calls, and will return the users locale in RFC 5646 format.
   * @type {string}
   * @memberof User
   */
  locale?: string;
  /**
   * The unique login id for the user.  This is what the user uses to log in to Canvas.
   * @type {string}
   * @memberof User
   */
  loginId?: string;
  /**
   * The name of the user.
   * @type {string}
   * @memberof User
   */
  name?: string;
  /**
   * A short name the user has selected, for use in conversations or other less formal places through the site.
   * @type {string}
   * @memberof User
   */
  shortName?: string;
  /**
   * The id of the SIS import.  This field is only included if the user came from a SIS import and has permissions to manage SIS information.
   * @type {number}
   * @memberof User
   */
  sisImportId?: number;
  /**
   * The SIS ID associated with the user.  This field is only included if the user came from a SIS import and has permissions to view SIS information.
   * @type {string}
   * @memberof User
   */
  sisUserId?: string;
  /**
   * The name of the user that is should be used for sorting groups of users, such as in the gradebook.
   * @type {string}
   * @memberof User
   */
  sortableName?: string;
  /**
   * Optional: This field is only returned in certain API calls, and will return the IANA time zone name of the user's preferred timezone.
   * @type {string}
   * @memberof User
   */
  timeZone?: string;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;

  return isInstance;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    avatarState: !exists(json, "avatar_state")
      ? undefined
      : json["avatar_state"],
    avatarUrl: !exists(json, "avatar_url") ? undefined : json["avatar_url"],
    bio: !exists(json, "bio") ? undefined : json["bio"],
    email: !exists(json, "email") ? undefined : json["email"],
    enrollments: !exists(json, "enrollments")
      ? undefined
      : (json["enrollments"] as Array<any>).map(EnrollmentFromJSON),
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    id: json["id"],
    integrationId: !exists(json, "integration_id")
      ? undefined
      : json["integration_id"],
    lastLogin: !exists(json, "last_login")
      ? undefined
      : new Date(json["last_login"]),
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    locale: !exists(json, "locale") ? undefined : json["locale"],
    loginId: !exists(json, "login_id") ? undefined : json["login_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    shortName: !exists(json, "short_name") ? undefined : json["short_name"],
    sisImportId: !exists(json, "sis_import_id")
      ? undefined
      : json["sis_import_id"],
    sisUserId: !exists(json, "sis_user_id") ? undefined : json["sis_user_id"],
    sortableName: !exists(json, "sortable_name")
      ? undefined
      : json["sortable_name"],
    timeZone: !exists(json, "time_zone") ? undefined : json["time_zone"],
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    avatar_state: value.avatarState,
    avatar_url: value.avatarUrl,
    bio: value.bio,
    email: value.email,
    enrollments:
      value.enrollments === undefined
        ? undefined
        : (value.enrollments as Array<any>).map(EnrollmentToJSON),
    first_name: value.firstName,
    id: value.id,
    integration_id: value.integrationId,
    last_login:
      value.lastLogin === undefined ? undefined : value.lastLogin.toISOString(),
    last_name: value.lastName,
    locale: value.locale,
    login_id: value.loginId,
    name: value.name,
    short_name: value.shortName,
    sis_import_id: value.sisImportId,
    sis_user_id: value.sisUserId,
    sortable_name: value.sortableName,
    time_zone: value.timeZone,
  };
}
