/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Grade
 */
export interface Grade {
  /**
   * The user's current grade in the class. Only included if user has permissions to view this grade.
   * @type {string}
   * @memberof Grade
   */
  currentGrade?: string;
  /**
   * The total points the user has earned in the class. Only included if user has permissions to view this score and 'current_points' is passed in the request's 'include' parameter.
   * @type {number}
   * @memberof Grade
   */
  currentPoints?: number;
  /**
   * The user's current score in the class. Only included if user has permissions to view this score.
   * @type {string}
   * @memberof Grade
   */
  currentScore?: string;
  /**
   * The user's final grade for the class. Only included if user has permissions to view this grade.
   * @type {string}
   * @memberof Grade
   */
  finalGrade?: string;
  /**
   * The user's final score for the class. Only included if user has permissions to view this score.
   * @type {string}
   * @memberof Grade
   */
  finalScore?: string;
  /**
   * The URL to the Canvas web UI page for the user's grades, if this is a student enrollment.
   * @type {string}
   * @memberof Grade
   */
  htmlUrl?: string;
  /**
   * The user's current grade in the class including muted/unposted assignments. Only included if user has permissions to view this grade, typically teachers, TAs, and admins.
   * @type {string}
   * @memberof Grade
   */
  unpostedCurrentGrade?: string;
  /**
   * The total points the user has earned in the class, including muted/unposted assignments. Only included if user has permissions to view this score (typically teachers, TAs, and admins) and 'current_points' is passed in the request's 'include' parameter.
   * @type {number}
   * @memberof Grade
   */
  unpostedCurrentPoints?: number;
  /**
   * The user's current score in the class including muted/unposted assignments. Only included if user has permissions to view this score, typically teachers, TAs, and admins..
   * @type {string}
   * @memberof Grade
   */
  unpostedCurrentScore?: string;
  /**
   * The user's final grade for the class including muted/unposted assignments. Only included if user has permissions to view this grade, typically teachers, TAs, and admins..
   * @type {string}
   * @memberof Grade
   */
  unpostedFinalGrade?: string;
  /**
   * The user's final score for the class including muted/unposted assignments. Only included if user has permissions to view this score, typically teachers, TAs, and admins..
   * @type {string}
   * @memberof Grade
   */
  unpostedFinalScore?: string;
}

/**
 * Check if a given object implements the Grade interface.
 */
export function instanceOfGrade(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GradeFromJSON(json: any): Grade {
  return GradeFromJSONTyped(json, false);
}

export function GradeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Grade {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currentGrade: !exists(json, "current_grade")
      ? undefined
      : json["current_grade"],
    currentPoints: !exists(json, "current_points")
      ? undefined
      : json["current_points"],
    currentScore: !exists(json, "current_score")
      ? undefined
      : json["current_score"],
    finalGrade: !exists(json, "final_grade") ? undefined : json["final_grade"],
    finalScore: !exists(json, "final_score") ? undefined : json["final_score"],
    htmlUrl: !exists(json, "html_url") ? undefined : json["html_url"],
    unpostedCurrentGrade: !exists(json, "unposted_current_grade")
      ? undefined
      : json["unposted_current_grade"],
    unpostedCurrentPoints: !exists(json, "unposted_current_points")
      ? undefined
      : json["unposted_current_points"],
    unpostedCurrentScore: !exists(json, "unposted_current_score")
      ? undefined
      : json["unposted_current_score"],
    unpostedFinalGrade: !exists(json, "unposted_final_grade")
      ? undefined
      : json["unposted_final_grade"],
    unpostedFinalScore: !exists(json, "unposted_final_score")
      ? undefined
      : json["unposted_final_score"],
  };
}

export function GradeToJSON(value?: Grade | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current_grade: value.currentGrade,
    current_points: value.currentPoints,
    current_score: value.currentScore,
    final_grade: value.finalGrade,
    final_score: value.finalScore,
    html_url: value.htmlUrl,
    unposted_current_grade: value.unpostedCurrentGrade,
    unposted_current_points: value.unpostedCurrentPoints,
    unposted_current_score: value.unpostedCurrentScore,
    unposted_final_grade: value.unpostedFinalGrade,
    unposted_final_score: value.unpostedFinalScore,
  };
}
