import {
  Button,
  Divider,
  Flex,
  Heading,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";

// This layout page is applied to all pages after logging in
// All markup that should be shared across all pages should be placed here
export default function Layout() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <div style={containerStyle}>
      <Flex direction="row" justifyContent="space-between">
        <Heading level={1}>AI Course Builder - C3L</Heading>
        <Flex direction="row" alignItems="center">
          <Heading level={4}>Hi {user ? user.username : ""}</Heading>
          <Button onClick={signOut}>Sign out</Button>
        </Flex>
      </Flex>
      <Divider />
      {/* Think of <Outlet> like 'yield', it's where the the child routes will render - whatever they may be */}
      <Outlet />
    </div>
  );
}

const containerStyle: React.CSSProperties = {
  width: "90%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: 20,
};
