import { Box } from "@mui/material";
import { useState } from "react";
import { Course, Week } from "../../../graphql/API";
import { WeekAccordion } from "./WeekAccordion";

interface WeekAccordionListProps {
  value: Week[];
  onChange: (value: Week[]) => Promise<void>;
  isLoading: boolean;
  course: Course;
}

export function WeekAccordionList(props: WeekAccordionListProps) {
  const [selectedElement, setSelectedElement] = useState<string | null>(null);

  return (
    <Box>
      {props.value.map((week, index) => (
        <WeekAccordion
          value={week}
					key={JSON.stringify(week)}
          onUpdate={(value) => {
            const newWeeks = [...props.value];
            newWeeks[index] = value;
						setSelectedElement(null);
            return props.onChange(newWeeks);
          }}
          onDelete={() => {
            const newWeeks = [...props.value];
            newWeeks.splice(index, 1);
						setSelectedElement(null);
            return props.onChange(newWeeks);
          }}
          index={index}
          isSelected={selectedElement === `week-${index}`}
          onAccordionChange={(isExpanded) => {
            if (isExpanded) {
              setSelectedElement(`week-${index}`);
            } else {
              setSelectedElement(null);
            }
          }}
          isLoading={props.isLoading}
          course={props.course}
        />
      ))}
    </Box>
  );
}

export default WeekAccordionList;
