/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Answer } from "./Answer";
import { AnswerFromJSON, AnswerFromJSONTyped, AnswerToJSON } from "./Answer";

/**
 *
 * @export
 * @interface QuizQuestion
 */
export interface QuizQuestion {
  /**
   * An array of available answers to display to the student.
   * @type {Array<Answer>}
   * @memberof QuizQuestion
   */
  answers?: Array<Answer>;
  /**
   * The comments to display if the student answers the question correctly.
   * @type {string}
   * @memberof QuizQuestion
   */
  correctComments?: string;
  /**
   * The ID of the quiz question.
   * @type {number}
   * @memberof QuizQuestion
   */
  id: number;
  /**
   * The comments to display if the student answers incorrectly.
   * @type {string}
   * @memberof QuizQuestion
   */
  incorrectComments?: string;
  /**
   * The comments to display regardless of how the student answered.
   * @type {string}
   * @memberof QuizQuestion
   */
  neutralComments?: string;
  /**
   * The maximum amount of points possible received for getting this question correct.
   * @type {number}
   * @memberof QuizQuestion
   */
  pointsPossible?: number;
  /**
   * The order in which the question will be retrieved and displayed.
   * @type {number}
   * @memberof QuizQuestion
   */
  position?: number;
  /**
   * The name of the question.
   * @type {string}
   * @memberof QuizQuestion
   */
  questionName?: string;
  /**
   * The text of the question.
   * @type {string}
   * @memberof QuizQuestion
   */
  questionText?: string;
  /**
   * The type of the question.
   * @type {string}
   * @memberof QuizQuestion
   */
  questionType?: string;
  /**
   * The ID of the Quiz the question belongs to.
   * @type {number}
   * @memberof QuizQuestion
   */
  quizId: number;
}

/**
 * Check if a given object implements the QuizQuestion interface.
 */
export function instanceOfQuizQuestion(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "quizId" in value;

  return isInstance;
}

export function QuizQuestionFromJSON(json: any): QuizQuestion {
  return QuizQuestionFromJSONTyped(json, false);
}

export function QuizQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuizQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    answers: !exists(json, "answers")
      ? undefined
      : (json["answers"] as Array<any>).map(AnswerFromJSON),
    correctComments: !exists(json, "correct_comments")
      ? undefined
      : json["correct_comments"],
    id: json["id"],
    incorrectComments: !exists(json, "incorrect_comments")
      ? undefined
      : json["incorrect_comments"],
    neutralComments: !exists(json, "neutral_comments")
      ? undefined
      : json["neutral_comments"],
    pointsPossible: !exists(json, "points_possible")
      ? undefined
      : json["points_possible"],
    position: !exists(json, "position") ? undefined : json["position"],
    questionName: !exists(json, "question_name")
      ? undefined
      : json["question_name"],
    questionText: !exists(json, "question_text")
      ? undefined
      : json["question_text"],
    questionType: !exists(json, "question_type")
      ? undefined
      : json["question_type"],
    quizId: json["quiz_id"],
  };
}

export function QuizQuestionToJSON(value?: QuizQuestion | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    answers:
      value.answers === undefined
        ? undefined
        : (value.answers as Array<any>).map(AnswerToJSON),
    correct_comments: value.correctComments,
    id: value.id,
    incorrect_comments: value.incorrectComments,
    neutral_comments: value.neutralComments,
    points_possible: value.pointsPossible,
    position: value.position,
    question_name: value.questionName,
    question_text: value.questionText,
    question_type: value.questionType,
    quiz_id: value.quizId,
  };
}
