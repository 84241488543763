import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function OAuth2Response() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [authenticated, setAuthenticated] = useState(false);
  const code = query.get("code");
	// Get the base path of the current location
	const basePath = window.location.protocol + "//" + window.location.host;

  useEffect(() => {
    if (code) {
      // Send a POST request to the OAuth2 token endpoint
      fetch("https://canvas-dev.c3l.ai/login/oauth2/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_id: "10000000000006",
          client_secret:
            "TWDUQCy3I09X5p1QfM7X1ivlzXQ2dEi1DcpRPDpmGgXej69875XYDTvBgk5DC7Sv",
          code,
          redirect_uri: `${basePath}/oauth2/response`,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          setAuthenticated(true);
          // Store the auth in local storage
          localStorage.setItem("canvas_auth", JSON.stringify(json));
        })
        .catch((error) => {
          setAuthenticated(true);
          console.log(error);
        });
    }
  }, [code, basePath]);

  if (!authenticated) {
    return <div>Authenticating...</div>;
  } else {
    return <Navigate to="/" />;
  }
}
