import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RedirectToCorrectStep from "./components/RedirectToCorrectStep";
import Home from "./views/Home";
import Layout from "./views/Layout";
import OAuth2Response from "./views/OAuth2Response";
import Wizard from "./views/Wizard";
import CourseDetails from "./views/wizard_steps/CourseDetails";
import CourseObjectives from "./views/wizard_steps/CourseObjectives";
import Topics from "./views/wizard_steps/Topics";
import AssessmentSettings from "./views/wizard_steps/AssessmentSettings";
import WeeklyStructure from "./views/wizard_steps/WeeklyStructure";
import ReviewAndExport from "./views/wizard_steps/ReviewAndExport";
import Quizzes from "./views/wizard_steps/Quizzes";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="course/:id" element={<Wizard />}>
              <Route path="step" element={<RedirectToCorrectStep />} />
              <Route path="step-1" element={<CourseDetails />} />
              <Route path="step-2" element={<CourseObjectives />} />
              <Route path="step-3" element={<Topics />} />
              <Route path="step-4" element={<WeeklyStructure />} />
              <Route path="step-5" element={<AssessmentSettings />} />
              <Route path="step-6" element={<Quizzes />} />
              <Route path="step-7" element={<ReviewAndExport />} />
              {
                //<Route path="step-3" element={<GraduateQualities />} />
                //<Route path="step-4" element={<AssessmentTypes />} />
                //<Route path="step-3" element={<CourseStructure />} />
                //<Route path="step-4" element={<ExportToCanvas />} />
              }
            </Route>
            <Route path="oauth2/response" element={<OAuth2Response />} />
            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={"error"} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
//<Route path="*" element={<Navigate to="/" />} />

export default App;
