import { Box, Stack } from "@mui/material";
import React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { onArrayDragEnd, uniqueId } from "./helpers";

interface DragAndDropListProps<T> {
  /** The id to be applied to the Droppable container */
  droppableId: string;
  /** The array of items to be dragged and dropped */
  value: T[];
  /** On change handler for when the array is reordered */
  onChange: (value: T[]) => Promise<void>;
  children: (item: T, index: number) => React.ReactNode;
}

/**
 * Takes a list of items and allows them to be reordered via drag and drop
 *
 * @remarks The child to this component is a function that passes the item and index to be rendered
 */
function DragAndDropList<T>(props: DragAndDropListProps<T>) {
  const { droppableId, value, onChange, children } = props;
  return (
    <DragDropContext
      onDragEnd={(result) => onArrayDragEnd(result, value, onChange)}
    >
      <StrictModeDroppable droppableId={droppableId}>
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            {value.map((item, index) => (
              <Draggable
                key={uniqueId(item, index)}
                draggableId={uniqueId(item, index)}
                index={index}
              >
                {(provided) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicatorIcon sx={{ cursor: "grab" }} />
                    <Box flexGrow={1}>{children(item, index)}</Box>
                  </Stack>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
}

export default DragAndDropList;
