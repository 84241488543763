import { LanguageModelFunction, LanguageModelMessage } from "../../../API";
import { Course } from "../../../graphql/API";
import quizSchema from "../../../language-model-schemas/quizzes.json";

export const defaultPrompt =
  "Can you please create some quizzes for my course?";

export function getConversationContext(course: Course): LanguageModelMessage[] {
  const extraContextAboutTheQuiz = `I have these existing quizzes so far that I would like to update, given here as a JSON object in triple quotes """${JSON.stringify(
    course.quizzes
  )}""".`;

  return [
    {
      role: "system",
      content: `You are a learning designer helping design a course for a university in Australia. You always return incredibly descriptive responses. You always double check and make sure that the returned repsonse is in the absolute correct JSON schema format.`,
    },
    {
      role: "user",
      content: `Hi, I'm designing a course called '${
        course.name
      }'? The course objectives are given as a JSON object here in triple quotes """${JSON.stringify(
        course.courseObjectives
      )}""". The topics for my course are given as a JSON object here in triple quotes """${JSON.stringify(
        course.topics
      )}""". I'm trying to create some quizzes for my course. There should be a quiz every ${
        course.assessmentSettings.quizFrequencyInWeeks
      } week(s), and each quiz should have ${
        course.assessmentSettings.quizNumberOfQuestions
      } questions. ${
        course.quizzes.length > 0 ? extraContextAboutTheQuiz : ""
      }`,
    },
  ];
}

export function suggestQuizzesSchema(course: Course): LanguageModelFunction[] {
  const schema = [
    {
      name: "suggest_quizzes",
      description: "Suggest quizzes for a course",
      parameters: {
        type: "object",
        properties: {
          response: quizSchema,
        },
        required: ["response"],
      },
    },
  ];
  // Made sure all the concepts and objectives are covered
  schema[0].parameters.properties.response.items.properties.conceptsCoveredInTheQuiz.items.enum =
    course.topics.map((topic) => topic.topicConcepts).flat();

  schema[0].parameters.properties.response.items.properties.courseObjectivesCoveredByTheQuiz.items.enum =
    course.courseObjectives.map(
      (objective) =>
        `${objective.objectiveDescription}`
    );
  return schema;
}

export function improveQuiz(course: Course): LanguageModelFunction[] {
  const schema = [
    {
      name: "update_quiz",
      description: "Update a quiz in the list of quizzes",
      parameters: {
        type: "object",
        properties: {
          response: quizSchema.items,
        },
        required: ["response"],
      },
    },
  ];

  // Made sure all the concepts and objectives are covered
  schema[0].parameters.properties.response.properties.conceptsCoveredInTheQuiz.items.enum =
    course.topics.map((topic) => topic.topicConcepts).flat();

  schema[0].parameters.properties.response.properties.courseObjectivesCoveredByTheQuiz.items.enum =
    course.courseObjectives.map(
      (objective) =>
        `${objective.objectiveDescription}`
    );
  return schema;
}
