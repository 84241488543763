/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface PageRevision
 */
export interface PageRevision {
  /**
   * the historic page contents
   * @type {string}
   * @memberof PageRevision
   */
  body?: string;
  /**
   *
   * @type {User}
   * @memberof PageRevision
   */
  editedBy?: User;
  /**
   * whether this is the latest revision or not
   * @type {boolean}
   * @memberof PageRevision
   */
  latest?: boolean;
  /**
   * an identifier for this revision of the page
   * @type {number}
   * @memberof PageRevision
   */
  revisionId?: number;
  /**
   * the historic page title
   * @type {string}
   * @memberof PageRevision
   */
  title?: string;
  /**
   * the time when this revision was saved
   * @type {Date}
   * @memberof PageRevision
   */
  updatedAt?: Date;
  /**
   * the following fields are not included in the index action and may be omitted from the show action via summary=1 the historic url of the page
   * @type {string}
   * @memberof PageRevision
   */
  url?: string;
}

/**
 * Check if a given object implements the PageRevision interface.
 */
export function instanceOfPageRevision(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PageRevisionFromJSON(json: any): PageRevision {
  return PageRevisionFromJSONTyped(json, false);
}

export function PageRevisionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PageRevision {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    body: !exists(json, "body") ? undefined : json["body"],
    editedBy: !exists(json, "edited_by")
      ? undefined
      : UserFromJSON(json["edited_by"]),
    latest: !exists(json, "latest") ? undefined : json["latest"],
    revisionId: !exists(json, "revision_id") ? undefined : json["revision_id"],
    title: !exists(json, "title") ? undefined : json["title"],
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function PageRevisionToJSON(value?: PageRevision | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    body: value.body,
    edited_by: UserToJSON(value.editedBy),
    latest: value.latest,
    revision_id: value.revisionId,
    title: value.title,
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    url: value.url,
  };
}
