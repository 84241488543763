/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CalendarLink
 */
export interface CalendarLink {
  /**
   * The URL of the calendar in ICS format
   * @type {string}
   * @memberof CalendarLink
   */
  ics?: string;
}

/**
 * Check if a given object implements the CalendarLink interface.
 */
export function instanceOfCalendarLink(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CalendarLinkFromJSON(json: any): CalendarLink {
  return CalendarLinkFromJSONTyped(json, false);
}

export function CalendarLinkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalendarLink {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ics: !exists(json, "ics") ? undefined : json["ics"],
  };
}

export function CalendarLinkToJSON(value?: CalendarLink | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ics: value.ics,
  };
}
