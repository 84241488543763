/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Grade } from "./Grade";
import { GradeFromJSON, GradeFromJSONTyped, GradeToJSON } from "./Grade";
import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface Enrollment
 */
export interface Enrollment {
  /**
   * The unique id of the associated user. Will be null unless type is ObserverEnrollment.
   * @type {number}
   * @memberof Enrollment
   */
  associatedUserId?: number;
  /**
   * The unique id of the course.
   * @type {number}
   * @memberof Enrollment
   */
  courseId?: number;
  /**
   * The Course Integration ID in which the enrollment is associated. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Enrollment
   */
  courseIntegrationId?: string;
  /**
   * The unique id of the user's section.
   * @type {number}
   * @memberof Enrollment
   */
  courseSectionId?: number;
  /**
   * The created time of the enrollment, in ISO8601 format.
   * @type {Date}
   * @memberof Enrollment
   */
  createdAt?: Date;
  /**
   * optional: The id of the currently active grading period, if one exists. If the course the enrollment belongs to does not have grading periods, or if no currently active grading period exists, the value will be null. (applies only to student enrollments, and only available in course endpoints)
   * @type {number}
   * @memberof Enrollment
   */
  currentGradingPeriodId?: number;
  /**
   * optional: The name of the currently active grading period, if one exists. If the course the enrollment belongs to does not have grading periods, or if no currently active grading period exists, the value will be null. (applies only to student enrollments, and only available in course endpoints)
   * @type {string}
   * @memberof Enrollment
   */
  currentGradingPeriodTitle?: string;
  /**
   * The user's override grade for the current grading period.
   * @type {string}
   * @memberof Enrollment
   */
  currentPeriodOverrideGrade?: string;
  /**
   * The user's override score for the current grading period.
   * @type {number}
   * @memberof Enrollment
   */
  currentPeriodOverrideScore?: number;
  /**
   * optional: The letter grade equivalent of current_period_unposted_current_score, if available. Only included if user has permission to view this grade, typically teachers, TAs, and admins. If the course the enrollment belongs to does not have grading periods, or if no currently active grading period exists, the value will be null. (applies only to student enrollments, and only available in course endpoints)
   * @type {string}
   * @memberof Enrollment
   */
  currentPeriodUnpostedCurrentGrade?: string;
  /**
   * optional: The student's score in the course for the current grading period, including muted/unposted assignments. Only included if user has permission to view this score, typically teachers, TAs, and admins. If the course the enrollment belongs to does not have grading periods, or if no currently active grading period exists, the value will be null. (applies only to student enrollments, and only available in course endpoints)
   * @type {number}
   * @memberof Enrollment
   */
  currentPeriodUnpostedCurrentScore?: number;
  /**
   * optional: The letter grade equivalent of current_period_unposted_final_score, if available. Only included if user has permission to view this grade, typically teachers, TAs, and admins. If the course the enrollment belongs to does not have grading periods, or if no currently active grading period exists, the value will be null. (applies only to student enrollments, and only available in course endpoints)
   * @type {string}
   * @memberof Enrollment
   */
  currentPeriodUnpostedFinalGrade?: string;
  /**
   * optional: The student's score in the course for the current grading period, including muted/unposted assignments and including ungraded assignments with a score of 0. Only included if user has permission to view this score, typically teachers, TAs, and admins. If the course the enrollment belongs to does not have grading periods, or if no currently active grading period exists, the value will be null. (applies only to student enrollments, and only available in course endpoints)
   * @type {number}
   * @memberof Enrollment
   */
  currentPeriodUnpostedFinalScore?: number;
  /**
   * The end time of the enrollment, in ISO8601 format.
   * @type {Date}
   * @memberof Enrollment
   */
  endAt?: Date;
  /**
   * The state of the user's enrollment in the course.
   * @type {string}
   * @memberof Enrollment
   */
  enrollmentState?: string;
  /**
   *
   * @type {Grade}
   * @memberof Enrollment
   */
  grades?: Grade;
  /**
   * optional: Indicates whether the course the enrollment belongs to has grading periods set up. (applies only to student enrollments, and only available in course endpoints)
   * @type {boolean}
   * @memberof Enrollment
   */
  hasGradingPeriods?: boolean;
  /**
   * The URL to the Canvas web UI page for this course enrollment.
   * @type {string}
   * @memberof Enrollment
   */
  htmlUrl?: string;
  /**
   * The ID of the enrollment.
   * @type {number}
   * @memberof Enrollment
   */
  id?: number;
  /**
   * The last activity time of the user for the enrollment, in ISO8601 format.
   * @type {Date}
   * @memberof Enrollment
   */
  lastActivityAt?: Date;
  /**
   * The last attended date of the user for the enrollment in a course, in ISO8601 format.
   * @type {Date}
   * @memberof Enrollment
   */
  lastAttendedAt?: Date;
  /**
   * User can only access his or her own course section.
   * @type {boolean}
   * @memberof Enrollment
   */
  limitPrivilegesToCourseSection?: boolean;
  /**
   * The user's override grade for the course.
   * @type {string}
   * @memberof Enrollment
   */
  overrideGrade?: string;
  /**
   * The user's override score for the course.
   * @type {number}
   * @memberof Enrollment
   */
  overrideScore?: number;
  /**
   * The enrollment role, for course-level permissions. This field will match `type` if the enrollment role has not been customized.
   * @type {string}
   * @memberof Enrollment
   */
  role?: string;
  /**
   * The id of the enrollment role.
   * @type {number}
   * @memberof Enrollment
   */
  roleId?: number;
  /**
   * The unique id of the user's account.
   * @type {number}
   * @memberof Enrollment
   */
  rootAccountId?: number;
  /**
   * The Section Integration ID in which the enrollment is associated. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Enrollment
   */
  sectionIntegrationId?: string;
  /**
   * The SIS Account ID in which the enrollment is associated. Only displayed if present. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Enrollment
   */
  sisAccountId?: string;
  /**
   * The SIS Course ID in which the enrollment is associated. Only displayed if present. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Enrollment
   */
  sisCourseId?: string;
  /**
   * The unique identifier for the SIS import. This field is only included if the user has permission to manage SIS information.
   * @type {number}
   * @memberof Enrollment
   */
  sisImportId?: number;
  /**
   * The SIS Section ID in which the enrollment is associated. Only displayed if present. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Enrollment
   */
  sisSectionId?: string;
  /**
   * The SIS User ID in which the enrollment is associated. Only displayed if present. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Enrollment
   */
  sisUserId?: string;
  /**
   * The start time of the enrollment, in ISO8601 format.
   * @type {Date}
   * @memberof Enrollment
   */
  startAt?: Date;
  /**
   * The total activity time of the user for the enrollment, in seconds.
   * @type {number}
   * @memberof Enrollment
   */
  totalActivityTime?: number;
  /**
   * optional: Indicates whether the course the enrollment belongs to has the Display Totals for 'All Grading Periods' feature enabled. (applies only to student enrollments, and only available in course endpoints)
   * @type {boolean}
   * @memberof Enrollment
   */
  totalsForAllGradingPeriodsOption?: boolean;
  /**
   * The enrollment type. One of 'StudentEnrollment', 'TeacherEnrollment', 'TaEnrollment', 'DesignerEnrollment', 'ObserverEnrollment'.
   * @type {string}
   * @memberof Enrollment
   */
  type?: string;
  /**
   * The user's current grade in the class including muted/unposted assignments. Only included if user has permissions to view this grade, typically teachers, TAs, and admins.
   * @type {string}
   * @memberof Enrollment
   */
  unpostedCurrentGrade?: string;
  /**
   * The user's current score in the class including muted/unposted assignments. Only included if user has permissions to view this score, typically teachers, TAs, and admins..
   * @type {string}
   * @memberof Enrollment
   */
  unpostedCurrentScore?: string;
  /**
   * The user's final grade for the class including muted/unposted assignments. Only included if user has permissions to view this grade, typically teachers, TAs, and admins..
   * @type {string}
   * @memberof Enrollment
   */
  unpostedFinalGrade?: string;
  /**
   * The user's final score for the class including muted/unposted assignments. Only included if user has permissions to view this score, typically teachers, TAs, and admins..
   * @type {string}
   * @memberof Enrollment
   */
  unpostedFinalScore?: string;
  /**
   * The updated time of the enrollment, in ISO8601 format.
   * @type {Date}
   * @memberof Enrollment
   */
  updatedAt?: Date;
  /**
   *
   * @type {User}
   * @memberof Enrollment
   */
  user?: User;
  /**
   * The unique id of the user.
   * @type {number}
   * @memberof Enrollment
   */
  userId?: number;
}

/**
 * Check if a given object implements the Enrollment interface.
 */
export function instanceOfEnrollment(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EnrollmentFromJSON(json: any): Enrollment {
  return EnrollmentFromJSONTyped(json, false);
}

export function EnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Enrollment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    associatedUserId: !exists(json, "associated_user_id")
      ? undefined
      : json["associated_user_id"],
    courseId: !exists(json, "course_id") ? undefined : json["course_id"],
    courseIntegrationId: !exists(json, "course_integration_id")
      ? undefined
      : json["course_integration_id"],
    courseSectionId: !exists(json, "course_section_id")
      ? undefined
      : json["course_section_id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    currentGradingPeriodId: !exists(json, "current_grading_period_id")
      ? undefined
      : json["current_grading_period_id"],
    currentGradingPeriodTitle: !exists(json, "current_grading_period_title")
      ? undefined
      : json["current_grading_period_title"],
    currentPeriodOverrideGrade: !exists(json, "current_period_override_grade")
      ? undefined
      : json["current_period_override_grade"],
    currentPeriodOverrideScore: !exists(json, "current_period_override_score")
      ? undefined
      : json["current_period_override_score"],
    currentPeriodUnpostedCurrentGrade: !exists(
      json,
      "current_period_unposted_current_grade"
    )
      ? undefined
      : json["current_period_unposted_current_grade"],
    currentPeriodUnpostedCurrentScore: !exists(
      json,
      "current_period_unposted_current_score"
    )
      ? undefined
      : json["current_period_unposted_current_score"],
    currentPeriodUnpostedFinalGrade: !exists(
      json,
      "current_period_unposted_final_grade"
    )
      ? undefined
      : json["current_period_unposted_final_grade"],
    currentPeriodUnpostedFinalScore: !exists(
      json,
      "current_period_unposted_final_score"
    )
      ? undefined
      : json["current_period_unposted_final_score"],
    endAt: !exists(json, "end_at") ? undefined : new Date(json["end_at"]),
    enrollmentState: !exists(json, "enrollment_state")
      ? undefined
      : json["enrollment_state"],
    grades: !exists(json, "grades") ? undefined : GradeFromJSON(json["grades"]),
    hasGradingPeriods: !exists(json, "has_grading_periods")
      ? undefined
      : json["has_grading_periods"],
    htmlUrl: !exists(json, "html_url") ? undefined : json["html_url"],
    id: !exists(json, "id") ? undefined : json["id"],
    lastActivityAt: !exists(json, "last_activity_at")
      ? undefined
      : new Date(json["last_activity_at"]),
    lastAttendedAt: !exists(json, "last_attended_at")
      ? undefined
      : new Date(json["last_attended_at"]),
    limitPrivilegesToCourseSection: !exists(
      json,
      "limit_privileges_to_course_section"
    )
      ? undefined
      : json["limit_privileges_to_course_section"],
    overrideGrade: !exists(json, "override_grade")
      ? undefined
      : json["override_grade"],
    overrideScore: !exists(json, "override_score")
      ? undefined
      : json["override_score"],
    role: !exists(json, "role") ? undefined : json["role"],
    roleId: !exists(json, "role_id") ? undefined : json["role_id"],
    rootAccountId: !exists(json, "root_account_id")
      ? undefined
      : json["root_account_id"],
    sectionIntegrationId: !exists(json, "section_integration_id")
      ? undefined
      : json["section_integration_id"],
    sisAccountId: !exists(json, "sis_account_id")
      ? undefined
      : json["sis_account_id"],
    sisCourseId: !exists(json, "sis_course_id")
      ? undefined
      : json["sis_course_id"],
    sisImportId: !exists(json, "sis_import_id")
      ? undefined
      : json["sis_import_id"],
    sisSectionId: !exists(json, "sis_section_id")
      ? undefined
      : json["sis_section_id"],
    sisUserId: !exists(json, "sis_user_id") ? undefined : json["sis_user_id"],
    startAt: !exists(json, "start_at") ? undefined : new Date(json["start_at"]),
    totalActivityTime: !exists(json, "total_activity_time")
      ? undefined
      : json["total_activity_time"],
    totalsForAllGradingPeriodsOption: !exists(
      json,
      "totals_for_all_grading_periods_option"
    )
      ? undefined
      : json["totals_for_all_grading_periods_option"],
    type: !exists(json, "type") ? undefined : json["type"],
    unpostedCurrentGrade: !exists(json, "unposted_current_grade")
      ? undefined
      : json["unposted_current_grade"],
    unpostedCurrentScore: !exists(json, "unposted_current_score")
      ? undefined
      : json["unposted_current_score"],
    unpostedFinalGrade: !exists(json, "unposted_final_grade")
      ? undefined
      : json["unposted_final_grade"],
    unpostedFinalScore: !exists(json, "unposted_final_score")
      ? undefined
      : json["unposted_final_score"],
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    user: !exists(json, "user") ? undefined : UserFromJSON(json["user"]),
    userId: !exists(json, "user_id") ? undefined : json["user_id"],
  };
}

export function EnrollmentToJSON(value?: Enrollment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    associated_user_id: value.associatedUserId,
    course_id: value.courseId,
    course_integration_id: value.courseIntegrationId,
    course_section_id: value.courseSectionId,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    current_grading_period_id: value.currentGradingPeriodId,
    current_grading_period_title: value.currentGradingPeriodTitle,
    current_period_override_grade: value.currentPeriodOverrideGrade,
    current_period_override_score: value.currentPeriodOverrideScore,
    current_period_unposted_current_grade:
      value.currentPeriodUnpostedCurrentGrade,
    current_period_unposted_current_score:
      value.currentPeriodUnpostedCurrentScore,
    current_period_unposted_final_grade: value.currentPeriodUnpostedFinalGrade,
    current_period_unposted_final_score: value.currentPeriodUnpostedFinalScore,
    end_at: value.endAt === undefined ? undefined : value.endAt.toISOString(),
    enrollment_state: value.enrollmentState,
    grades: GradeToJSON(value.grades),
    has_grading_periods: value.hasGradingPeriods,
    html_url: value.htmlUrl,
    id: value.id,
    last_activity_at:
      value.lastActivityAt === undefined
        ? undefined
        : value.lastActivityAt.toISOString(),
    last_attended_at:
      value.lastAttendedAt === undefined
        ? undefined
        : value.lastAttendedAt.toISOString(),
    limit_privileges_to_course_section: value.limitPrivilegesToCourseSection,
    override_grade: value.overrideGrade,
    override_score: value.overrideScore,
    role: value.role,
    role_id: value.roleId,
    root_account_id: value.rootAccountId,
    section_integration_id: value.sectionIntegrationId,
    sis_account_id: value.sisAccountId,
    sis_course_id: value.sisCourseId,
    sis_import_id: value.sisImportId,
    sis_section_id: value.sisSectionId,
    sis_user_id: value.sisUserId,
    start_at:
      value.startAt === undefined ? undefined : value.startAt.toISOString(),
    total_activity_time: value.totalActivityTime,
    totals_for_all_grading_periods_option:
      value.totalsForAllGradingPeriodsOption,
    type: value.type,
    unposted_current_grade: value.unpostedCurrentGrade,
    unposted_current_score: value.unpostedCurrentScore,
    unposted_final_grade: value.unpostedFinalGrade,
    unposted_final_score: value.unpostedFinalScore,
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    user: UserToJSON(value.user),
    user_id: value.userId,
  };
}
