import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface WizardPageTitleProps {
  title: string;
}

function WizardPageTitle({ title }: WizardPageTitleProps) {
  const navigate = useNavigate();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h2" gutterBottom mt={2}>
        {title}
      </Typography>
      <Button
        variant="outlined"
        onClick={() => {
          navigate(`/`);
        }}
      >
        Exit
      </Button>
    </Stack>
  );
}

export default WizardPageTitle;
