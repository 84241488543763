import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCourseMutation } from "../../../API";
import AiAssistantButton from "../../../components/AiAssistantButton";
import { withCourse } from "../../../components/withCourse";
import WizardPageTitle from "../../../components/WizardPageTitle";
import { Course } from "../../../graphql/API";
import { getConversationContext, defaultPrompt, suggestTopicsSchema } from "./prompts";
import TopicCardList from "./TopicCardList";

function Topics({ course }: { course: Course }) {
  const { id, topics } = course;
  const navigate = useNavigate();
  const updateTopicMutation = useCourseMutation(id);

  // We have 2 of the same mutation because we want to show different "isLoading" states for adding a topic vs updating a topic
  const createTopicMutation = useCourseMutation(id);

  function addTopic() {
    createTopicMutation.mutate({
      id,
      topics: [
        ...course.topics,
        {
          topicDescription: "New Topic",
          topicConcepts: ["Concept 1", "Concept 2"],
        },
      ],
    });
  }

  return (
    <Box>
      <WizardPageTitle title="Topics" />
      <Typography variant="body1" gutterBottom>
        Now we have our course objectives, let's define the main topics you want
        to cover in <em>{course.name}</em>.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Later on, we'll use these topics to create a good structure across the{" "}
        {course.lengthInWeeks} weeks of the course.
      </Typography>
      <Typography variant="body1" gutterBottom>
        As before, you can click <strong>AI ASSIST</strong> to ask the AI for a
        good starting point.
      </Typography>
      <Stack direction="row" spacing={2} mt={2} mb={2}>
        <LoadingButton
          variant="contained"
          startIcon={<AddIcon />}
          color="info"
          onClick={addTopic}
          loading={createTopicMutation.isLoading}
        >
          <span>Add</span>
        </LoadingButton>
        <AiAssistantButton
          conversationContext={getConversationContext(course)}
          defaultPrompt={defaultPrompt}
          functionsSchema={suggestTopicsSchema}
          onAssistantResponse={(data: any) => {
            updateTopicMutation.mutate({
              id,
              topics: data,
            });
          }}
        />
      </Stack>
      <TopicCardList
				key={topics.length}
        value={topics}
				course={course} // Needed so the AI assistant has access to the course
        isLoading={updateTopicMutation.isLoading}
        onChange={(newTopics) => {
          return updateTopicMutation.mutateAsync({
            id,
            topics: [...newTopics],
          });
        }}
      />
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        onClick={() => {
          navigate(`/course/${id}/step-4`);
        }}
      >
        Next
      </Button>
    </Box>
  );
}

export default withCourse(Topics);
