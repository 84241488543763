/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Object representing a due date for an assignment or quiz. If the due date came from an assignment override, it will have an 'id' field.
 * @export
 * @interface AssignmentDate
 */
export interface AssignmentDate {
  /**
   * (Optional, present if 'id' is missing) whether this date represents the assignment's or quiz's default due date
   * @type {boolean}
   * @memberof AssignmentDate
   */
  base?: boolean;
  /**
   * The due date for the assignment. Must be between the unlock date and the lock date if there are lock dates
   * @type {Date}
   * @memberof AssignmentDate
   */
  dueAt?: Date;
  /**
   * (Optional, missing if 'base' is present) id of the assignment override this date represents
   * @type {number}
   * @memberof AssignmentDate
   */
  id?: number;
  /**
   * The lock date for the assignment. Must be after the due date if there is a due date.
   * @type {Date}
   * @memberof AssignmentDate
   */
  lockAt?: Date;
  /**
   *
   * @type {string}
   * @memberof AssignmentDate
   */
  title?: string;
  /**
   * The unlock date for the assignment. Must be before the due date if there is a due date.
   * @type {Date}
   * @memberof AssignmentDate
   */
  unlockAt?: Date;
}

/**
 * Check if a given object implements the AssignmentDate interface.
 */
export function instanceOfAssignmentDate(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AssignmentDateFromJSON(json: any): AssignmentDate {
  return AssignmentDateFromJSONTyped(json, false);
}

export function AssignmentDateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssignmentDate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    base: !exists(json, "base") ? undefined : json["base"],
    dueAt: !exists(json, "due_at") ? undefined : new Date(json["due_at"]),
    id: !exists(json, "id") ? undefined : json["id"],
    lockAt: !exists(json, "lock_at") ? undefined : new Date(json["lock_at"]),
    title: !exists(json, "title") ? undefined : json["title"],
    unlockAt: !exists(json, "unlock_at")
      ? undefined
      : new Date(json["unlock_at"]),
  };
}

export function AssignmentDateToJSON(value?: AssignmentDate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    base: value.base,
    due_at: value.dueAt === undefined ? undefined : value.dueAt.toISOString(),
    id: value.id,
    lock_at:
      value.lockAt === undefined ? undefined : value.lockAt.toISOString(),
    title: value.title,
    unlock_at:
      value.unlockAt === undefined ? undefined : value.unlockAt.toISOString(),
  };
}
