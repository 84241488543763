/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CourseProgress
 */
export interface CourseProgress {
  /**
   * date the course was completed. null if the course has not been completed by this user
   * @type {Date}
   * @memberof CourseProgress
   */
  completedAt?: Date;
  /**
   * url to next module item that has an unmet requirement. null if the user has completed the course or the current module does not require sequential progress
   * @type {string}
   * @memberof CourseProgress
   */
  nextRequirementUrl?: string;
  /**
   * total number of requirements the user has completed from all modules
   * @type {number}
   * @memberof CourseProgress
   */
  requirementCompletedCount?: number;
  /**
   * total number of requirements from all modules
   * @type {number}
   * @memberof CourseProgress
   */
  requirementCount?: number;
}

/**
 * Check if a given object implements the CourseProgress interface.
 */
export function instanceOfCourseProgress(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CourseProgressFromJSON(json: any): CourseProgress {
  return CourseProgressFromJSONTyped(json, false);
}

export function CourseProgressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CourseProgress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completedAt: !exists(json, "completed_at")
      ? undefined
      : new Date(json["completed_at"]),
    nextRequirementUrl: !exists(json, "next_requirement_url")
      ? undefined
      : json["next_requirement_url"],
    requirementCompletedCount: !exists(json, "requirement_completed_count")
      ? undefined
      : json["requirement_completed_count"],
    requirementCount: !exists(json, "requirement_count")
      ? undefined
      : json["requirement_count"],
  };
}

export function CourseProgressToJSON(value?: CourseProgress | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completed_at:
      value.completedAt === undefined
        ? undefined
        : value.completedAt.toISOString(),
    next_requirement_url: value.nextRequirementUrl,
    requirement_completed_count: value.requirementCompletedCount,
    requirement_count: value.requirementCount,
  };
}
