/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Permissions the user has for the quiz
 * @export
 * @interface QuizPermissions
 */
export interface QuizPermissions {
  /**
   * whether the user may create a new quiz
   * @type {boolean}
   * @memberof QuizPermissions
   */
  create?: boolean;
  /**
   * whether the user may edit, update, or delete the quiz
   * @type {boolean}
   * @memberof QuizPermissions
   */
  manage?: boolean;
  /**
   * whether the user can view the quiz
   * @type {boolean}
   * @memberof QuizPermissions
   */
  read?: boolean;
  /**
   * whether the user may view quiz statistics for this quiz
   * @type {boolean}
   * @memberof QuizPermissions
   */
  readStatistics?: boolean;
  /**
   * whether the user may review grades for all quiz submissions for this quiz
   * @type {boolean}
   * @memberof QuizPermissions
   */
  reviewGrades?: boolean;
  /**
   * whether the user may submit a submission for the quiz
   * @type {boolean}
   * @memberof QuizPermissions
   */
  submit?: boolean;
  /**
   * whether the user may update the quiz
   * @type {boolean}
   * @memberof QuizPermissions
   */
  update?: boolean;
}

/**
 * Check if a given object implements the QuizPermissions interface.
 */
export function instanceOfQuizPermissions(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function QuizPermissionsFromJSON(json: any): QuizPermissions {
  return QuizPermissionsFromJSONTyped(json, false);
}

export function QuizPermissionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuizPermissions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    create: !exists(json, "create") ? undefined : json["create"],
    manage: !exists(json, "manage") ? undefined : json["manage"],
    read: !exists(json, "read") ? undefined : json["read"],
    readStatistics: !exists(json, "read_statistics")
      ? undefined
      : json["read_statistics"],
    reviewGrades: !exists(json, "review_grades")
      ? undefined
      : json["review_grades"],
    submit: !exists(json, "submit") ? undefined : json["submit"],
    update: !exists(json, "update") ? undefined : json["update"],
  };
}

export function QuizPermissionsToJSON(value?: QuizPermissions | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    create: value.create,
    manage: value.manage,
    read: value.read,
    read_statistics: value.readStatistics,
    review_grades: value.reviewGrades,
    submit: value.submit,
    update: value.update,
  };
}
