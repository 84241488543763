import { Divider, Flex } from "@aws-amplify/ui-react";
import { NavLink, Outlet, useParams } from "react-router-dom";

const WizardStepLink = ({
  id,
  stepNumber,
  stepName,
}: {
  id: string | undefined;
  stepNumber: number | string;
  stepName: string;
}) => {
  return (
    <li>
      <NavLink
        to={`/course/${id}/step-${stepNumber}`}
        style={({ isActive }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: "black",
          };
        }}
      >
        {`${stepNumber}. ${stepName}`}
      </NavLink>
    </li>
  );
};

export default function NewCourse() {
  let { id } = useParams();

  return (
    <Flex direction="row">
      <div style={{ flex: "1", height: "80vh" }}>
        <nav>
          <ul>
            <WizardStepLink id={id} stepNumber={1} stepName="Course Details" />
            <WizardStepLink
              id={id}
              stepNumber={2}
              stepName="Course Objectives"
            />
            <WizardStepLink id={id} stepNumber={3} stepName="Topics" />
            <WizardStepLink id={id} stepNumber={4} stepName="Weekly Structure" />
            <WizardStepLink id={id} stepNumber={5} stepName="Assessments" />
            <WizardStepLink id={id} stepNumber={6} stepName="Quizzes" />
            <WizardStepLink id={id} stepNumber={7} stepName="Review and Export" />
            {
              //<WizardStepLink
              //id={id}
              //stepNumber={3}
              //stepName="Course Structure"
              ///>
              //<WizardStepLink
              //id={id}
              //stepNumber={4}
              //stepName="Export To Canvas"
              ///>
            }
          </ul>
        </nav>
      </div>
      <Divider orientation="vertical" />
      <div style={{ flex: "6" }}>
        <Outlet />
      </div>
    </Flex>
  );
}
