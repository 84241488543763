import React from "react";
import DragAndDropList from "../../../components/DragAndDropList";
import { Course, Topic } from "../../../graphql/API";
import { useAsyncState } from "../../../hooks/useAsyncState";
import { TopicCard } from "./TopicCard";

interface TopicCardListProps {
  value: Topic[];
  onChange: (value: Topic[]) => Promise<void>;
  isLoading: boolean;
  course: Course;
}

export function TopicCardList(props: TopicCardListProps) {
  // This litle bit of internal state allows for smoother UI updates
  const [value, onChange] = useAsyncState(props.value, props.onChange);
  const [selectedTopic, setSelectedTopic] = React.useState<Number | null>(null);

  return (
    <DragAndDropList
      droppableId="topics-list"
      value={value}
      onChange={async (value) => {
        setSelectedTopic(null);
        await onChange(value);
      }}
		>
      {(topic, index) => (
        <TopicCard
					key={JSON.stringify(topic)}
          value={topic}
          onUpdate={async (newTopic) => {
            setSelectedTopic(null);
            const newTopics = [...value];
            newTopics[index] = newTopic;
            await onChange(newTopics);
          }}
          onDelete={async () => {
            setSelectedTopic(null);
            const newTopics = [...value];
            newTopics.splice(index, 1); // Remove the element at index
            await onChange(newTopics);
          }}
          isSelected={selectedTopic === index}
          isLoading={props.isLoading}
          onCardClick={() => {
            if (selectedTopic !== index) setSelectedTopic(index);
            else setSelectedTopic(null);
          }}
          course={props.course}
        />
      )}
    </DragAndDropList>
  );
}

export default TopicCardList;
