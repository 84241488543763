/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ModuleItem } from "./ModuleItem";
import {
  ModuleItemFromJSON,
  ModuleItemFromJSONTyped,
  ModuleItemToJSON,
} from "./ModuleItem";

/**
 *
 * @export
 * @interface ModuleItemSequenceNode
 */
export interface ModuleItemSequenceNode {
  /**
   *
   * @type {ModuleItem}
   * @memberof ModuleItemSequenceNode
   */
  current?: ModuleItem;
  /**
   * The conditional release rule for the module item, if applicable
   * @type {object}
   * @memberof ModuleItemSequenceNode
   */
  masteryPath?: object;
  /**
   *
   * @type {ModuleItem}
   * @memberof ModuleItemSequenceNode
   */
  next?: ModuleItem;
  /**
   *
   * @type {ModuleItem}
   * @memberof ModuleItemSequenceNode
   */
  prev?: ModuleItem;
}

/**
 * Check if a given object implements the ModuleItemSequenceNode interface.
 */
export function instanceOfModuleItemSequenceNode(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ModuleItemSequenceNodeFromJSON(
  json: any
): ModuleItemSequenceNode {
  return ModuleItemSequenceNodeFromJSONTyped(json, false);
}

export function ModuleItemSequenceNodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ModuleItemSequenceNode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    current: !exists(json, "current")
      ? undefined
      : ModuleItemFromJSON(json["current"]),
    masteryPath: !exists(json, "mastery_path")
      ? undefined
      : json["mastery_path"],
    next: !exists(json, "next") ? undefined : ModuleItemFromJSON(json["next"]),
    prev: !exists(json, "prev") ? undefined : ModuleItemFromJSON(json["prev"]),
  };
}

export function ModuleItemSequenceNodeToJSON(
  value?: ModuleItemSequenceNode | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current: ModuleItemToJSON(value.current),
    mastery_path: value.masteryPath,
    next: ModuleItemToJSON(value.next),
    prev: ModuleItemToJSON(value.prev),
  };
}
