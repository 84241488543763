import { Placeholder } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Navigate, useParams } from "react-router-dom";
import { useCourseQuery } from "../API";
import { Course } from "../graphql/API";

// Higher Order Component (HOC) to inject the current
// course into the component's props
export function withCourse(
  Component: React.ComponentType<{
    course: Course;
  }>
): () => React.ComponentElement<any, any> {
  return () => {
    const { id } = useParams();
    if (!id) {
      console.error(
        "No course ID found in the URL, redirecting to the home page"
      );
      return <Navigate to="/" />;
    }
    const course = useCourseQuery(id);
    if (course.isError) {
      console.error("Error fetching the course", course.error);
      return <Navigate to="/" />;
    }

    if (course.isLoading) {
      return <Placeholder style={{ height: "80vh", marginTop: "15px" }} />;
    }

    return <Component course={course.data} />;
  };
}
