/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CalendarLink } from "./CalendarLink";
import {
  CalendarLinkFromJSON,
  CalendarLinkFromJSONTyped,
  CalendarLinkToJSON,
} from "./CalendarLink";
import type { CourseProgress } from "./CourseProgress";
import {
  CourseProgressFromJSON,
  CourseProgressFromJSONTyped,
  CourseProgressToJSON,
} from "./CourseProgress";
import type { Enrollment } from "./Enrollment";
import {
  EnrollmentFromJSON,
  EnrollmentFromJSONTyped,
  EnrollmentToJSON,
} from "./Enrollment";
import type { GradingPeriod } from "./GradingPeriod";
import {
  GradingPeriodFromJSON,
  GradingPeriodFromJSONTyped,
  GradingPeriodToJSON,
} from "./GradingPeriod";
import type { Term } from "./Term";
import { TermFromJSON, TermFromJSONTyped, TermToJSON } from "./Term";

/**
 *
 * @export
 * @interface Course
 */
export interface Course {
  /**
   * optional: this will be true if this user is currently prevented from viewing the course because of date restriction settings
   * @type {boolean}
   * @memberof Course
   */
  accessRestrictedByDate?: boolean;
  /**
   * the account associated with the course
   * @type {number}
   * @memberof Course
   */
  accountId?: number;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  allowStudentAssignmentEdits?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  allowStudentForumAttachments?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  allowWikiComments?: boolean;
  /**
   * weight final grade based on assignment group percentages
   * @type {boolean}
   * @memberof Course
   */
  applyAssignmentGroupWeights?: boolean;
  /**
   * optional: whether the course is set as a Blueprint Course (blueprint fields require the Blueprint Courses feature)
   * @type {boolean}
   * @memberof Course
   */
  blueprint?: boolean;
  /**
   * optional: Set of restrictions applied to all locked course objects
   * @type {object}
   * @memberof Course
   */
  blueprintRestrictions?: object;
  /**
   * optional: Sets of restrictions differentiated by object type applied to locked course objects
   * @type {object}
   * @memberof Course
   */
  blueprintRestrictionsByObjectType?: object;
  /**
   *
   * @type {CalendarLink}
   * @memberof Course
   */
  calendar?: CalendarLink;
  /**
   * the course code
   * @type {string}
   * @memberof Course
   */
  courseCode?: string;
  /**
   *
   * @type {string}
   * @memberof Course
   */
  courseFormat?: string;
  /**
   *
   * @type {CourseProgress}
   * @memberof Course
   */
  courseProgress?: CourseProgress;
  /**
   * the date the course was created.
   * @type {Date}
   * @memberof Course
   */
  createdAt?: Date;
  /**
   * the type of page that users will see when they first visit the course - 'feed': Recent Activity Dashboard - 'wiki': Wiki Front Page - 'modules': Course Modules/Sections Page - 'assignments': Course Assignments List - 'syllabus': Course Syllabus Page other types may be added in the future
   * @type {string}
   * @memberof Course
   */
  defaultView?: string;
  /**
   * the end date for the course, if applicable
   * @type {Date}
   * @memberof Course
   */
  endAt?: Date;
  /**
   * the enrollment term associated with the course
   * @type {number}
   * @memberof Course
   */
  enrollmentTermId?: number;
  /**
   * A list of enrollments linking the current user to the course. for student enrollments, grading information may be included if include[]=total_scores
   * @type {Array<Enrollment>}
   * @memberof Course
   */
  enrollments?: Array<Enrollment>;
  /**
   * the grade_passback_setting set on the course
   * @type {string}
   * @memberof Course
   */
  gradePassbackSetting?: string;
  /**
   * A list of grading periods associated with the course
   * @type {Array<GradingPeriod>}
   * @memberof Course
   */
  gradingPeriods?: Array<GradingPeriod>;
  /**
   * the grading standard associated with the course
   * @type {number}
   * @memberof Course
   */
  gradingStandardId?: number;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  hideFinalGrades?: boolean;
  /**
   * the unique identifier for the course
   * @type {number}
   * @memberof Course
   */
  id?: number;
  /**
   * the integration identifier for the course, if defined. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Course
   */
  integrationId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  isPublic?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  isPublicToAuthUsers?: boolean;
  /**
   *
   * @type {string}
   * @memberof Course
   */
  license?: string;
  /**
   * the course-set locale, if applicable
   * @type {string}
   * @memberof Course
   */
  locale?: string;
  /**
   * the full name of the course. If the requesting user has set a nickname for the course, the nickname will be shown here.
   * @type {string}
   * @memberof Course
   */
  name?: string;
  /**
   * optional: the number of submissions needing grading returned only if the current user has grading rights and include[]=needs_grading_count
   * @type {number}
   * @memberof Course
   */
  needsGradingCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  openEnrollment?: boolean;
  /**
   * the actual course name. This field is returned only if the requesting user has set a nickname for the course.
   * @type {string}
   * @memberof Course
   */
  originalName?: string;
  /**
   * optional: the permissions the user has for the course. returned only for a single course and include[]=permissions
   * @type {object}
   * @memberof Course
   */
  permissions?: object;
  /**
   * optional: the public description of the course
   * @type {string}
   * @memberof Course
   */
  publicDescription?: string;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  publicSyllabus?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  publicSyllabusToAuth?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  restrictEnrollmentsToCourseDates?: boolean;
  /**
   * the root account associated with the course
   * @type {number}
   * @memberof Course
   */
  rootAccountId?: number;
  /**
   *
   * @type {boolean}
   * @memberof Course
   */
  selfEnrollment?: boolean;
  /**
   * the SIS identifier for the course, if defined. This field is only included if the user has permission to view SIS information.
   * @type {string}
   * @memberof Course
   */
  sisCourseId?: string;
  /**
   * the unique identifier for the SIS import. This field is only included if the user has permission to manage SIS information.
   * @type {number}
   * @memberof Course
   */
  sisImportId?: number;
  /**
   * the start date for the course, if applicable
   * @type {Date}
   * @memberof Course
   */
  startAt?: Date;
  /**
   *
   * @type {number}
   * @memberof Course
   */
  storageQuotaMb?: number;
  /**
   *
   * @type {number}
   * @memberof Course
   */
  storageQuotaUsedMb?: number;
  /**
   * optional: user-generated HTML for the course syllabus
   * @type {string}
   * @memberof Course
   */
  syllabusBody?: string;
  /**
   * optional: whether the course is set as a template (requires the Course Templates feature)
   * @type {boolean}
   * @memberof Course
   */
  template?: boolean;
  /**
   *
   * @type {Term}
   * @memberof Course
   */
  term?: Term;
  /**
   * The course's IANA time zone name.
   * @type {string}
   * @memberof Course
   */
  timeZone?: string;
  /**
   * optional: the total number of active and invited students in the course
   * @type {number}
   * @memberof Course
   */
  totalStudents?: number;
  /**
   * the UUID of the course
   * @type {string}
   * @memberof Course
   */
  uuid?: string;
  /**
   * the current state of the course one of 'unpublished', 'available', 'completed', or 'deleted'
   * @type {string}
   * @memberof Course
   */
  workflowState?: string;
}

/**
 * Check if a given object implements the Course interface.
 */
export function instanceOfCourse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CourseFromJSON(json: any): Course {
  return CourseFromJSONTyped(json, false);
}

export function CourseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Course {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessRestrictedByDate: !exists(json, "access_restricted_by_date")
      ? undefined
      : json["access_restricted_by_date"],
    accountId: !exists(json, "account_id") ? undefined : json["account_id"],
    allowStudentAssignmentEdits: !exists(json, "allow_student_assignment_edits")
      ? undefined
      : json["allow_student_assignment_edits"],
    allowStudentForumAttachments: !exists(
      json,
      "allow_student_forum_attachments"
    )
      ? undefined
      : json["allow_student_forum_attachments"],
    allowWikiComments: !exists(json, "allow_wiki_comments")
      ? undefined
      : json["allow_wiki_comments"],
    applyAssignmentGroupWeights: !exists(json, "apply_assignment_group_weights")
      ? undefined
      : json["apply_assignment_group_weights"],
    blueprint: !exists(json, "blueprint") ? undefined : json["blueprint"],
    blueprintRestrictions: !exists(json, "blueprint_restrictions")
      ? undefined
      : json["blueprint_restrictions"],
    blueprintRestrictionsByObjectType: !exists(
      json,
      "blueprint_restrictions_by_object_type"
    )
      ? undefined
      : json["blueprint_restrictions_by_object_type"],
    calendar: !exists(json, "calendar")
      ? undefined
      : CalendarLinkFromJSON(json["calendar"]),
    courseCode: !exists(json, "course_code") ? undefined : json["course_code"],
    courseFormat: !exists(json, "course_format")
      ? undefined
      : json["course_format"],
    courseProgress: !exists(json, "course_progress")
      ? undefined
      : CourseProgressFromJSON(json["course_progress"]),
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    defaultView: !exists(json, "default_view")
      ? undefined
      : json["default_view"],
    endAt: !exists(json, "end_at") ? undefined : new Date(json["end_at"]),
    enrollmentTermId: !exists(json, "enrollment_term_id")
      ? undefined
      : json["enrollment_term_id"],
    enrollments: !exists(json, "enrollments")
      ? undefined
      : (json["enrollments"] as Array<any>).map(EnrollmentFromJSON),
    gradePassbackSetting: !exists(json, "grade_passback_setting")
      ? undefined
      : json["grade_passback_setting"],
    gradingPeriods: !exists(json, "grading_periods")
      ? undefined
      : (json["grading_periods"] as Array<any>).map(GradingPeriodFromJSON),
    gradingStandardId: !exists(json, "grading_standard_id")
      ? undefined
      : json["grading_standard_id"],
    hideFinalGrades: !exists(json, "hide_final_grades")
      ? undefined
      : json["hide_final_grades"],
    id: !exists(json, "id") ? undefined : json["id"],
    integrationId: !exists(json, "integration_id")
      ? undefined
      : json["integration_id"],
    isPublic: !exists(json, "is_public") ? undefined : json["is_public"],
    isPublicToAuthUsers: !exists(json, "is_public_to_auth_users")
      ? undefined
      : json["is_public_to_auth_users"],
    license: !exists(json, "license") ? undefined : json["license"],
    locale: !exists(json, "locale") ? undefined : json["locale"],
    name: !exists(json, "name") ? undefined : json["name"],
    needsGradingCount: !exists(json, "needs_grading_count")
      ? undefined
      : json["needs_grading_count"],
    openEnrollment: !exists(json, "open_enrollment")
      ? undefined
      : json["open_enrollment"],
    originalName: !exists(json, "original_name")
      ? undefined
      : json["original_name"],
    permissions: !exists(json, "permissions") ? undefined : json["permissions"],
    publicDescription: !exists(json, "public_description")
      ? undefined
      : json["public_description"],
    publicSyllabus: !exists(json, "public_syllabus")
      ? undefined
      : json["public_syllabus"],
    publicSyllabusToAuth: !exists(json, "public_syllabus_to_auth")
      ? undefined
      : json["public_syllabus_to_auth"],
    restrictEnrollmentsToCourseDates: !exists(
      json,
      "restrict_enrollments_to_course_dates"
    )
      ? undefined
      : json["restrict_enrollments_to_course_dates"],
    rootAccountId: !exists(json, "root_account_id")
      ? undefined
      : json["root_account_id"],
    selfEnrollment: !exists(json, "self_enrollment")
      ? undefined
      : json["self_enrollment"],
    sisCourseId: !exists(json, "sis_course_id")
      ? undefined
      : json["sis_course_id"],
    sisImportId: !exists(json, "sis_import_id")
      ? undefined
      : json["sis_import_id"],
    startAt: !exists(json, "start_at") ? undefined : new Date(json["start_at"]),
    storageQuotaMb: !exists(json, "storage_quota_mb")
      ? undefined
      : json["storage_quota_mb"],
    storageQuotaUsedMb: !exists(json, "storage_quota_used_mb")
      ? undefined
      : json["storage_quota_used_mb"],
    syllabusBody: !exists(json, "syllabus_body")
      ? undefined
      : json["syllabus_body"],
    template: !exists(json, "template") ? undefined : json["template"],
    term: !exists(json, "term") ? undefined : TermFromJSON(json["term"]),
    timeZone: !exists(json, "time_zone") ? undefined : json["time_zone"],
    totalStudents: !exists(json, "total_students")
      ? undefined
      : json["total_students"],
    uuid: !exists(json, "uuid") ? undefined : json["uuid"],
    workflowState: !exists(json, "workflow_state")
      ? undefined
      : json["workflow_state"],
  };
}

export function CourseToJSON(value?: Course | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access_restricted_by_date: value.accessRestrictedByDate,
    account_id: value.accountId,
    allow_student_assignment_edits: value.allowStudentAssignmentEdits,
    allow_student_forum_attachments: value.allowStudentForumAttachments,
    allow_wiki_comments: value.allowWikiComments,
    apply_assignment_group_weights: value.applyAssignmentGroupWeights,
    blueprint: value.blueprint,
    blueprint_restrictions: value.blueprintRestrictions,
    blueprint_restrictions_by_object_type:
      value.blueprintRestrictionsByObjectType,
    calendar: CalendarLinkToJSON(value.calendar),
    course_code: value.courseCode,
    course_format: value.courseFormat,
    course_progress: CourseProgressToJSON(value.courseProgress),
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    default_view: value.defaultView,
    end_at: value.endAt === undefined ? undefined : value.endAt.toISOString(),
    enrollment_term_id: value.enrollmentTermId,
    enrollments:
      value.enrollments === undefined
        ? undefined
        : (value.enrollments as Array<any>).map(EnrollmentToJSON),
    grade_passback_setting: value.gradePassbackSetting,
    grading_periods:
      value.gradingPeriods === undefined
        ? undefined
        : (value.gradingPeriods as Array<any>).map(GradingPeriodToJSON),
    grading_standard_id: value.gradingStandardId,
    hide_final_grades: value.hideFinalGrades,
    id: value.id,
    integration_id: value.integrationId,
    is_public: value.isPublic,
    is_public_to_auth_users: value.isPublicToAuthUsers,
    license: value.license,
    locale: value.locale,
    name: value.name,
    needs_grading_count: value.needsGradingCount,
    open_enrollment: value.openEnrollment,
    original_name: value.originalName,
    permissions: value.permissions,
    public_description: value.publicDescription,
    public_syllabus: value.publicSyllabus,
    public_syllabus_to_auth: value.publicSyllabusToAuth,
    restrict_enrollments_to_course_dates:
      value.restrictEnrollmentsToCourseDates,
    root_account_id: value.rootAccountId,
    self_enrollment: value.selfEnrollment,
    sis_course_id: value.sisCourseId,
    sis_import_id: value.sisImportId,
    start_at:
      value.startAt === undefined ? undefined : value.startAt.toISOString(),
    storage_quota_mb: value.storageQuotaMb,
    storage_quota_used_mb: value.storageQuotaUsedMb,
    syllabus_body: value.syllabusBody,
    template: value.template,
    term: TermToJSON(value.term),
    time_zone: value.timeZone,
    total_students: value.totalStudents,
    uuid: value.uuid,
    workflow_state: value.workflowState,
  };
}
