import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCourseMutation } from "../../../API";
import AiAssistantButton from "../../../components/AiAssistantButton";
import { withCourse } from "../../../components/withCourse";
import WizardPageTitle from "../../../components/WizardPageTitle";
import { Course } from "../../../graphql/API";
import CourseObjectiveCardList from "./CourseObjectiveCardList";
import {
  defaultPrompt,
  getConversationContext,
  suggestCourseObjectivesSchema,
} from "./prompts";

function CourseObjectives({ course }: { course: Course }) {
  const { id, name, courseObjectives } = course;
  const navigate = useNavigate();

  const updateCourseObjectivesMutation = useCourseMutation(id);
  // We have 2 of the same mutation because we want to show different "isLoading" states for adding an objective vs updating an objective
  const createCourseObjectiveMutation = useCourseMutation(id);

  function addCourseObjective() {
    createCourseObjectiveMutation.mutate({
      id,
      courseObjectives: [
        ...course.courseObjectives,
        {
          objectiveDescription: "",
          objectiveReasoning: "",
        },
      ],
    });
  }

  return (
    <Box key={course.name}>
      <WizardPageTitle title="Course Objectives" />
      <Typography variant="body1" gutterBottom>
        Let's start by defining the course objectives for <em>{name}</em>.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you have existing course objectives, please add them by clicking{" "}
        <strong>ADD</strong>. Or you can click <strong>AI ASSIST</strong> and
        ask the AI to give you a good starting point.
      </Typography>
      <Stack direction="row" spacing={2} mt={2} mb={2}>
        <LoadingButton
          variant="contained"
          startIcon={<AddIcon />}
          color="info"
          onClick={addCourseObjective}
          loading={createCourseObjectiveMutation.isLoading}
        >
          <span>Add</span>
        </LoadingButton>
        <AiAssistantButton
          conversationContext={getConversationContext(course)}
          defaultPrompt={defaultPrompt}
          functionsSchema={suggestCourseObjectivesSchema}
          onAssistantResponse={(data: any) => {
            updateCourseObjectivesMutation.mutate({
              id,
              courseObjectives: data,
            });
          }}
        />
      </Stack>
      <CourseObjectiveCardList
				key={courseObjectives.length}
        value={courseObjectives}
        course={course} // Needed so the AI assistant has access to the course
        isLoading={updateCourseObjectivesMutation.isLoading}
        onChange={(newCourseObjectives) => {
          return updateCourseObjectivesMutation.mutateAsync({
            id,
            courseObjectives: [...newCourseObjectives],
          });
        }}
      />
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        onClick={() => {
          navigate(`/course/${id}/step-3`);
        }}
      >
        Next
      </Button>
    </Box>
  );
}

// Input course details

export default withCourse(CourseObjectives);
