/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GradingPeriod
 */
export interface GradingPeriod {
  /**
   * Grades can only be changed before the close date of the grading period.
   * @type {Date}
   * @memberof GradingPeriod
   */
  closeDate?: Date;
  /**
   * The end date of the grading period.
   * @type {Date}
   * @memberof GradingPeriod
   */
  endDate: Date;
  /**
   * The unique identifier for the grading period.
   * @type {number}
   * @memberof GradingPeriod
   */
  id: number;
  /**
   * If true, the grading period's close_date has passed.
   * @type {boolean}
   * @memberof GradingPeriod
   */
  isClosed?: boolean;
  /**
   * The start date of the grading period.
   * @type {Date}
   * @memberof GradingPeriod
   */
  startDate: Date;
  /**
   * The title for the grading period.
   * @type {string}
   * @memberof GradingPeriod
   */
  title?: string;
  /**
   * A weight value that contributes to the overall weight of a grading period set which is used to calculate how much assignments in this period contribute to the total grade
   * @type {number}
   * @memberof GradingPeriod
   */
  weight?: number;
}

/**
 * Check if a given object implements the GradingPeriod interface.
 */
export function instanceOfGradingPeriod(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "endDate" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "startDate" in value;

  return isInstance;
}

export function GradingPeriodFromJSON(json: any): GradingPeriod {
  return GradingPeriodFromJSONTyped(json, false);
}

export function GradingPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GradingPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    closeDate: !exists(json, "close_date")
      ? undefined
      : new Date(json["close_date"]),
    endDate: new Date(json["end_date"]),
    id: json["id"],
    isClosed: !exists(json, "is_closed") ? undefined : json["is_closed"],
    startDate: new Date(json["start_date"]),
    title: !exists(json, "title") ? undefined : json["title"],
    weight: !exists(json, "weight") ? undefined : json["weight"],
  };
}

export function GradingPeriodToJSON(value?: GradingPeriod | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    close_date:
      value.closeDate === undefined ? undefined : value.closeDate.toISOString(),
    end_date: value.endDate.toISOString(),
    id: value.id,
    is_closed: value.isClosed,
    start_date: value.startDate.toISOString(),
    title: value.title,
    weight: value.weight,
  };
}
