import { DropResult } from "react-beautiful-dnd";

/**
 * Moves an item within an array to a new position. This does not mutate the original array.
 *
 * @remarks Used mostly by react-beautiful-dnd components to get drag and drop functionality
 *
 * @param array - The array to be reordered
 * @param startIndex - The index of the item to be moved
 * @param endIndex - The index of the position to move the item to
 * @returns The reordered array
 */
export function reorderArray<T>(
  array: T[],
  startIndex: number,
  endIndex: number
): T[] {
  // Safety checks
  if (array.length === 0) {
    return [];
  }
  // Argument checks
  if (startIndex < 0 || startIndex >= array.length) {
    throw new Error("startIndex is out of bounds");
  }
  if (endIndex < 0 || endIndex >= array.length) {
    throw new Error("endIndex is out of bounds");
  }

  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

/**
 * DragEnd handler for react-beautiful-dnd enabled lists.
 *
 * @remarks Does the appropriate checks, reorders the array and then
 * calls the onChangeArray callback
 *
 * @param result - The DropResult object from react-beautiful-dnd
 * @param array - The array to be reordered
 * @param onChangeArray - The callback to be called with the reordered array
 */
export function onArrayDragEnd<T>(
  result: DropResult,
  array: T[],
  onChangeArray: (value: T[]) => Promise<void>
) {
  if (!result.destination) {
    // If dropping outside the array, do nothing
    return;
  }
  if (result.destination.index === result.source.index) {
    // If dropping in the same place, do nothing
    return;
  }

  const newArray = reorderArray(
    array,
    result.source.index,
    result.destination.index
  );
  onChangeArray(newArray);
}

/**
 * A crappy way of producing a unique id for an array of items
 * where each item on it's own doesn't have a good way of
 * uniquely identifying itself.
 */
export function uniqueId(item: any, index: number) {
  return `${index}-${JSON.stringify(item)}`;
}
