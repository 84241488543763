/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { LockInfo } from "./LockInfo";
import {
  LockInfoFromJSON,
  LockInfoFromJSONTyped,
  LockInfoToJSON,
} from "./LockInfo";

/**
 *
 * @export
 * @interface ContentDetails
 */
export interface ContentDetails {
  /**
   *
   * @type {Date}
   * @memberof ContentDetails
   */
  dueAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof ContentDetails
   */
  lockAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ContentDetails
   */
  lockExplanation?: string;
  /**
   *
   * @type {LockInfo}
   * @memberof ContentDetails
   */
  lockInfo?: LockInfo;
  /**
   *
   * @type {boolean}
   * @memberof ContentDetails
   */
  lockedForUser?: boolean;
  /**
   *
   * @type {number}
   * @memberof ContentDetails
   */
  pointsPossible?: number;
  /**
   *
   * @type {Date}
   * @memberof ContentDetails
   */
  unlockAt?: Date;
}

/**
 * Check if a given object implements the ContentDetails interface.
 */
export function instanceOfContentDetails(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ContentDetailsFromJSON(json: any): ContentDetails {
  return ContentDetailsFromJSONTyped(json, false);
}

export function ContentDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContentDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    dueAt: !exists(json, "due_at") ? undefined : new Date(json["due_at"]),
    lockAt: !exists(json, "lock_at") ? undefined : new Date(json["lock_at"]),
    lockExplanation: !exists(json, "lock_explanation")
      ? undefined
      : json["lock_explanation"],
    lockInfo: !exists(json, "lock_info")
      ? undefined
      : LockInfoFromJSON(json["lock_info"]),
    lockedForUser: !exists(json, "locked_for_user")
      ? undefined
      : json["locked_for_user"],
    pointsPossible: !exists(json, "points_possible")
      ? undefined
      : json["points_possible"],
    unlockAt: !exists(json, "unlock_at")
      ? undefined
      : new Date(json["unlock_at"]),
  };
}

export function ContentDetailsToJSON(value?: ContentDetails | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    due_at: value.dueAt === undefined ? undefined : value.dueAt.toISOString(),
    lock_at:
      value.lockAt === undefined ? undefined : value.lockAt.toISOString(),
    lock_explanation: value.lockExplanation,
    lock_info: LockInfoToJSON(value.lockInfo),
    locked_for_user: value.lockedForUser,
    points_possible: value.pointsPossible,
    unlock_at:
      value.unlockAt === undefined ? undefined : value.unlockAt.toISOString(),
  };
}
