import { LanguageModelFunction, LanguageModelMessage } from "../../../API";
import { Course } from "../../../graphql/API";
import courseObjectivesSchema from "../../../language-model-schemas/courseObjectives.json";

export const defaultPrompt =
  "Can you please suggest 5 course objectives for me?";

export function getConversationContext(course: Course): LanguageModelMessage[] {
  return [
    {
      role: "system",
      content:
        "You are a learning designer helping design a course for a university in Australia.",
    },
    {
      role: "user",
      content: `Hi, can you help me design a course called '${course.name}'? The course will be '${course.lengthInWeeks}' weeks long, and is aimed at ${course.studentSkillLevel} ${course.courseLevel} students. Right now I'm at the section where I need to decide on course objectives for the students.`,
    },
    {
      role: "assistant",
      content: `Sure thing, how can I help?`,
    },
  ];
}

export const suggestCourseObjectivesSchema: LanguageModelFunction[] = [
  {
    name: "suggest_course_objectives",
    description: "Suggest a list of detailed course objectives for a course",
    parameters: {
      type: "object",
      properties: {
        response: courseObjectivesSchema,
      },
      required: ["response"],
    },
  },
];

export const updateCourseObjectiveSchema: LanguageModelFunction[] = [
  {
    name: "update_course_objective",
    description: "Update a course objective for a course",
    parameters: {
      type: "object",
      properties: {
        response: courseObjectivesSchema.items,
      },
      required: ["response"],
    },
  },
];
