import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Chip } from "@mui/material";

function ConceptsCoveredChips(props: {
  values: string[];
  onChange?: (values: string[]) => void;
}) {
  const { values, onChange } = props;

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {values.map((value) =>
        !!onChange ? (
          <Chip
            clickable
            key={value}
            label={value}
            deleteIcon={
              <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
            }
            onDelete={() => {
              onChange(values.filter((v) => v !== value));
            }}
          />
        ) : (
          <Chip key={value} label={value} />
        )
      )}
    </Box>
  );
}

export default ConceptsCoveredChips;
