import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactNode, useRef, useState } from "react";
import AiAssistantButton from "../../../components/AiAssistantButton";
import ConceptsCoveredChips from "../../../components/ConceptsCoveredChips";
import { Course, Week } from "../../../graphql/API";
import { getConversationContext, improveWeekStructure } from "./prompts";

interface WeekAccordionProps {
  value: Week;
  index: number;
  onUpdate: (value: Week) => Promise<void>;
  onDelete: () => void;
  isSelected: boolean;
  onAccordionChange: (expanded: boolean) => void;
  isLoading: boolean;
  course: Course;
}

export function WeekAccordion(props: WeekAccordionProps) {
  const { value, isSelected, onAccordionChange, isLoading, index, course } =
    props;
  const [cardState, setCardState] = useState<"displaying" | "editing">(
    "displaying"
  );
  // This tracks whether the according is "opening" or not (in the middle of it's animation)
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [draftWeek, setDraftWeek] = React.useState<Week>(value);
  const accordionReference = useRef(null);

  return (
    <>
      <Box ref={accordionReference}>
        <Accordion
          variant="outlined"
          key={cardState}
          expanded={isSelected}
          sx={{ border: isSelected ? "3px solid lightblue" : undefined }}
          onChange={(_, expanded) => {
            setIsTransitioning(true);
            onAccordionChange(expanded);
          }}
          TransitionProps={{
            onEntered: () => {
              // Once the accordion has finished expanding, we can set the transition state to false
              // Then we can display the popper
              setIsTransitioning(false);
            },
            onEntering: (_, isAppearing) => {
              if (!isAppearing) {
                // On close we want to reset the card state back to displaying
                setCardState("displaying");
              }
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={2}>
              <Grid item>
                <Typography>
                  Week {index + 1}: {value.weekTitle}
                </Typography>
              </Grid>
              <Grid item>
                <ConceptsCoveredChips
                  values={value.conceptsCoveredDuringTheWeek}
                />
              </Grid>
            </Grid>
          </AccordionSummary>

          <Grow in={true}>
            {cardState === "displaying" ? (
              <AccordionDetails>
                <Typography variant="body1" gutterBottom>
                  <strong>Reasoning: </strong>
                  {value.weekReasoning}
                </Typography>
                <Typography variant="body1" gutterBottom mt={4}>
                  <strong>Suggested Learning Activities: </strong>
                </Typography>
                <List
                  dense
                  disablePadding
                  sx={{ listStyleType: "disc", pl: 4 }}
                >
                  {value.suggestedLearningActivities.map((activity, index) => (
                    <ListItem sx={{ display: "list-item" }} key={index}>
                      {activity}
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            ) : (
              // Editing
              <Stack spacing={2} p={3} alignItems="start">
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label="Title"
                  variant="outlined"
                  value={draftWeek.weekTitle}
                  onChange={(event) => {
                    setDraftWeek({
                      ...draftWeek,
                      weekTitle: event.target.value,
                    });
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel id="concepts-covered-label">
                    Concepts covered
                  </InputLabel>
                  <Select
                    labelId="concepts-covered-label"
                    label="Concepts covered"
                    multiple
                    value={draftWeek.conceptsCoveredDuringTheWeek}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      const newConcepts =
                        typeof value === "string" ? value.split(",") : value;
                      setDraftWeek({
                        ...draftWeek,
                        conceptsCoveredDuringTheWeek: newConcepts,
                      });
                    }}
                    renderValue={(selected: string[]) => (
                      <ConceptsCoveredChips
                        values={selected}
                        onChange={(values) => {
                          setDraftWeek({
                            ...draftWeek,
                            conceptsCoveredDuringTheWeek: values,
                          });
                        }}
                      />
                    )}
                  >
                    {course.topics.reduce((menuItems, topic) => {
                      menuItems.push(
                        <ListSubheader key={topic.topicDescription}>
                          {topic.topicDescription}
                        </ListSubheader>
                      );
                      // Concepts already chosen
                      const alreadySelectedConceptsInOtherWeeks = course.weeks
                        .filter((_, weekIndex) => weekIndex !== index) // Not this week
                        .map((week) => week.conceptsCoveredDuringTheWeek)
                        .flat();

                      return menuItems.concat(
                        ...topic.topicConcepts
                          .filter(
                            (concept) =>
                              !alreadySelectedConceptsInOtherWeeks.includes(
                                concept
                              )
                          )
                          .map((concept) => (
                            <MenuItem key={concept} value={concept}>
                              <Checkbox
                                checked={
                                  draftWeek.conceptsCoveredDuringTheWeek.indexOf(
                                    concept
                                  ) > -1
                                }
                              />
                              <ListItemText primary={concept} />
                            </MenuItem>
                          ))
                      );
                    }, [] as ReactNode[])}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  multiline
                  label="Reasoning"
                  variant="outlined"
                  value={draftWeek.weekReasoning}
                  onChange={(event) => {
                    setDraftWeek({
                      ...draftWeek,
                      weekReasoning: event.target.value,
                    });
                  }}
                />
                <TextField
                  multiline
                  fullWidth
                  label="Suggested Learning Activities"
                  variant="outlined"
                  value={draftWeek.suggestedLearningActivities.join("\n")}
                  onChange={(event) => {
                    setDraftWeek({
                      ...draftWeek,
                      suggestedLearningActivities:
                        event.target.value.split("\n"),
                    });
                  }}
                />
                <LoadingButton
                  variant="contained"
                  startIcon={<DoneIcon />}
                  loading={isLoading}
                  onClick={async () => {
                    await props.onUpdate(draftWeek);
                    setCardState("displaying");
                  }}
                >
                  Save
                </LoadingButton>
              </Stack>
            )}
          </Grow>
        </Accordion>
      </Box>
      <Popper
        open={isSelected && !isTransitioning && cardState === "displaying"}
        anchorEl={accordionReference.current}
        placement="bottom-end"
      >
        <Card variant="elevation">
          <Grid container spacing={2} p={1}>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                color="info"
                onClick={() => {
                  setCardState("editing");
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <AiAssistantButton
                conversationContext={getConversationContext(
                  props.course
                ).concat([
                  {
                    role: "user",
                    content: `I'm currently editing this particular week, given here as a JSON object in triple quotes """${JSON.stringify(
                      value
                    )}"""`,
                  },
                  {
                    role: "assistant",
                    content: `Ok, how would you like to change this week?`,
                  },
                ])}
                functionsSchema={improveWeekStructure(course)}
                placeholder={"How would you like to change this week?"}
                onAssistantResponse={async (data: any) => {
                  await props.onUpdate(data);
                  setCardState("displaying");
                }}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                startIcon={<DeleteIcon />}
                color="error"
                loading={isLoading}
                onClick={() => {
                  props.onDelete();
                }}
              >
                Delete
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </Popper>
    </>
  );
}
