/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      courseLevel
      studentSkillLevel
      lengthInWeeks
      courseObjectives {
        objectiveDescription
        objectiveReasoning
      }
      topics {
        topicDescription
        topicConcepts
      }
      assessmentSettings {
        hasMultipleChoiceQuizzes
        hasDiscussions
        quizFrequencyInWeeks
        quizNumberOfQuestions
        discussionFrequencyInWeeks
      }
      weeks {
        weekTitle
        conceptsCoveredDuringTheWeek
        weekReasoning
        suggestedLearningActivities
      }
      quizzes {
        quizHeldInWeek
        conceptsCoveredInTheQuiz
        courseObjectivesCoveredByTheQuiz
        quizQuestions {
          question
          multipleChoiceAnswers
        }
      }
      hasBeenExportedToCanvas
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      name
      courseLevel
      studentSkillLevel
      lengthInWeeks
      courseObjectives {
        objectiveDescription
        objectiveReasoning
      }
      topics {
        topicDescription
        topicConcepts
      }
      assessmentSettings {
        hasMultipleChoiceQuizzes
        hasDiscussions
        quizFrequencyInWeeks
        quizNumberOfQuestions
        discussionFrequencyInWeeks
      }
      weeks {
        weekTitle
        conceptsCoveredDuringTheWeek
        weekReasoning
        suggestedLearningActivities
      }
      quizzes {
        quizHeldInWeek
        conceptsCoveredInTheQuiz
        courseObjectivesCoveredByTheQuiz
        quizQuestions {
          question
          multipleChoiceAnswers
        }
      }
      hasBeenExportedToCanvas
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name
      courseLevel
      studentSkillLevel
      lengthInWeeks
      courseObjectives {
        objectiveDescription
        objectiveReasoning
      }
      topics {
        topicDescription
        topicConcepts
      }
      assessmentSettings {
        hasMultipleChoiceQuizzes
        hasDiscussions
        quizFrequencyInWeeks
        quizNumberOfQuestions
        discussionFrequencyInWeeks
      }
      weeks {
        weekTitle
        conceptsCoveredDuringTheWeek
        weekReasoning
        suggestedLearningActivities
      }
      quizzes {
        quizHeldInWeek
        conceptsCoveredInTheQuiz
        courseObjectivesCoveredByTheQuiz
        quizQuestions {
          question
          multipleChoiceAnswers
        }
      }
      hasBeenExportedToCanvas
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      oauth2_client_secret
      test
      createdAt
      updatedAt
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      oauth2_client_secret
      test
      createdAt
      updatedAt
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      oauth2_client_secret
      test
      createdAt
      updatedAt
    }
  }
`;
