/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompletionRequirement
 */
export interface CompletionRequirement {
  /**
   * whether the calling user has met this requirement (Optional; present only if the caller is a student or if the optional parameter 'student_id' is included)
   * @type {boolean}
   * @memberof CompletionRequirement
   */
  completed?: boolean;
  /**
   * minimum score required to complete (only present when type == 'min_score')
   * @type {number}
   * @memberof CompletionRequirement
   */
  minScore?: number;
  /**
   * one of 'must_view', 'must_submit', 'must_contribute', 'min_score', 'must_mark_done'
   * @type {string}
   * @memberof CompletionRequirement
   */
  type?: string;
}

/**
 * Check if a given object implements the CompletionRequirement interface.
 */
export function instanceOfCompletionRequirement(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CompletionRequirementFromJSON(
  json: any
): CompletionRequirement {
  return CompletionRequirementFromJSONTyped(json, false);
}

export function CompletionRequirementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompletionRequirement {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completed: !exists(json, "completed") ? undefined : json["completed"],
    minScore: !exists(json, "min_score") ? undefined : json["min_score"],
    type: !exists(json, "type") ? undefined : json["type"],
  };
}

export function CompletionRequirementToJSON(
  value?: CompletionRequirement | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completed: value.completed,
    min_score: value.minScore,
    type: value.type,
  };
}
