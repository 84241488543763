/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Answer
 */
export interface Answer {
  /**
   * Specific contextual comments for a particular answer.
   * @type {string}
   * @memberof Answer
   */
  answerComments?: string;
  /**
   * Used in matching questions.  The static value of the answer that will be displayed on the left for students to match for.
   * @type {string}
   * @memberof Answer
   */
  answerMatchLeft?: string;
  /**
   * Used in matching questions. The correct match for the value given in answer_match_left.  Will be displayed in a dropdown with the other answer_match_right values..
   * @type {string}
   * @memberof Answer
   */
  answerMatchRight?: string;
  /**
   * The text of the answer.
   * @type {string}
   * @memberof Answer
   */
  answerText: string;
  /**
   * An integer to determine correctness of the answer. Incorrect answers should be 0, correct answers should be 100.
   * @type {number}
   * @memberof Answer
   */
  answerWeight: number;
  /**
   * Used in numerical questions of type 'precision_answer'.  The value the answer should equal.
   * @type {number}
   * @memberof Answer
   */
  approximate?: number;
  /**
   * Used in fill in multiple blank and multiple dropdowns questions.
   * @type {number}
   * @memberof Answer
   */
  blankId?: number;
  /**
   * Used in numerical questions of type 'range_answer'. The end of the allowed range (inclusive).
   * @type {number}
   * @memberof Answer
   */
  end?: number;
  /**
   * Used in numerical questions of type 'exact_answer'.  The value the answer should equal.
   * @type {number}
   * @memberof Answer
   */
  exact?: number;
  /**
   * The unique identifier for the answer.  Do not supply if this answer is part of a new question
   * @type {number}
   * @memberof Answer
   */
  id?: number;
  /**
   * Used in numerical questions of type 'exact_answer'. The margin of error allowed for the student's answer.
   * @type {number}
   * @memberof Answer
   */
  margin?: number;
  /**
   * Used in matching questions. A list of distractors, delimited by new lines (
   * ) that will be seeded with all the answer_match_right values.
   * @type {string}
   * @memberof Answer
   */
  matchingAnswerIncorrectMatches?: string;
  /**
   * Used in numerical questions.  Values can be 'exact_answer', 'range_answer', or 'precision_answer'.
   * @type {string}
   * @memberof Answer
   */
  numericalAnswerType?: string;
  /**
   * Used in numerical questions of type 'precision_answer'. The numerical precision that will be used when comparing the student's answer.
   * @type {number}
   * @memberof Answer
   */
  precision?: number;
  /**
   * Used in numerical questions of type 'range_answer'. The start of the allowed range (inclusive).
   * @type {number}
   * @memberof Answer
   */
  start?: number;
  /**
   * Used in missing word questions.  The text to follow the missing word
   * @type {string}
   * @memberof Answer
   */
  textAfterAnswers?: string;
}

/**
 * Check if a given object implements the Answer interface.
 */
export function instanceOfAnswer(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "answerText" in value;
  isInstance = isInstance && "answerWeight" in value;

  return isInstance;
}

export function AnswerFromJSON(json: any): Answer {
  return AnswerFromJSONTyped(json, false);
}

export function AnswerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Answer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    answerComments: !exists(json, "answer_comments")
      ? undefined
      : json["answer_comments"],
    answerMatchLeft: !exists(json, "answer_match_left")
      ? undefined
      : json["answer_match_left"],
    answerMatchRight: !exists(json, "answer_match_right")
      ? undefined
      : json["answer_match_right"],
    answerText: json["answer_text"],
    answerWeight: json["answer_weight"],
    approximate: !exists(json, "approximate") ? undefined : json["approximate"],
    blankId: !exists(json, "blank_id") ? undefined : json["blank_id"],
    end: !exists(json, "end") ? undefined : json["end"],
    exact: !exists(json, "exact") ? undefined : json["exact"],
    id: !exists(json, "id") ? undefined : json["id"],
    margin: !exists(json, "margin") ? undefined : json["margin"],
    matchingAnswerIncorrectMatches: !exists(
      json,
      "matching_answer_incorrect_matches"
    )
      ? undefined
      : json["matching_answer_incorrect_matches"],
    numericalAnswerType: !exists(json, "numerical_answer_type")
      ? undefined
      : json["numerical_answer_type"],
    precision: !exists(json, "precision") ? undefined : json["precision"],
    start: !exists(json, "start") ? undefined : json["start"],
    textAfterAnswers: !exists(json, "text_after_answers")
      ? undefined
      : json["text_after_answers"],
  };
}

export function AnswerToJSON(value?: Answer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    answer_comments: value.answerComments,
    answer_match_left: value.answerMatchLeft,
    answer_match_right: value.answerMatchRight,
    answer_text: value.answerText,
    answer_weight: value.answerWeight,
    approximate: value.approximate,
    blank_id: value.blankId,
    end: value.end,
    exact: value.exact,
    id: value.id,
    margin: value.margin,
    matching_answer_incorrect_matches: value.matchingAnswerIncorrectMatches,
    numerical_answer_type: value.numericalAnswerType,
    precision: value.precision,
    start: value.start,
    text_after_answers: value.textAfterAnswers,
  };
}
