import { Answer, QuizQuestion, QuizQuestionFromJSON } from "../models";
import * as runtime from "../runtime";
import { CreateSingleQuizQuestionRequest, DefaultApi } from "./DefaultApi";

// This exists to fix up a small issue in the generated code when trying to pass
// arrays through as form parameters. The generated code doesn't handle this for some reason
export class OverrideDefaultApi extends DefaultApi {
  async createSingleQuizQuestionRaw(
    requestParameters: CreateSingleQuizQuestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<QuizQuestion>> {
    if (
      requestParameters.courseId === null ||
      requestParameters.courseId === undefined
    ) {
      throw new runtime.RequiredError(
        "courseId",
        "Required parameter requestParameters.courseId was null or undefined when calling createSingleQuizQuestion."
      );
    }

    if (
      requestParameters.quizId === null ||
      requestParameters.quizId === undefined
    ) {
      throw new runtime.RequiredError(
        "quizId",
        "Required parameter requestParameters.quizId was null or undefined when calling createSingleQuizQuestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: "application/x-www-form-urlencoded" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    //************* Start fix
    const camelToSnakeCase = (str: string) =>
      str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    if (requestParameters.questionAnswers) {
			const answers = JSON.parse(requestParameters.questionAnswers) as Answer[];
      answers.forEach((answer, index) => {
        Object.entries(answer).forEach(([key, value]) => {
          formParams.append(
            `question[answers][${index}][${camelToSnakeCase(key)}]`,
            value
          );
        });
      });
    }
    //************* End fix

    if (requestParameters.questionCorrectComments !== undefined) {
      formParams.append(
        "question[correct_comments]",
        requestParameters.questionCorrectComments as any
      );
    }

    if (requestParameters.questionIncorrectComments !== undefined) {
      formParams.append(
        "question[incorrect_comments]",
        requestParameters.questionIncorrectComments as any
      );
    }

    if (requestParameters.questionNeutralComments !== undefined) {
      formParams.append(
        "question[neutral_comments]",
        requestParameters.questionNeutralComments as any
      );
    }

    if (requestParameters.questionPointsPossible !== undefined) {
      formParams.append(
        "question[points_possible]",
        requestParameters.questionPointsPossible as any
      );
    }

    if (requestParameters.questionPosition !== undefined) {
      formParams.append(
        "question[position]",
        requestParameters.questionPosition as any
      );
    }

    if (requestParameters.questionQuestionName !== undefined) {
      formParams.append(
        "question[question_name]",
        requestParameters.questionQuestionName as any
      );
    }

    if (requestParameters.questionQuestionText !== undefined) {
      formParams.append(
        "question[question_text]",
        requestParameters.questionQuestionText as any
      );
    }

    if (requestParameters.questionQuestionType !== undefined) {
      formParams.append(
        "question[question_type]",
        requestParameters.questionQuestionType as any
      );
    }

    if (requestParameters.questionQuizGroupId !== undefined) {
      formParams.append(
        "question[quiz_group_id]",
        requestParameters.questionQuizGroupId as any
      );
    }

    if (requestParameters.questionTextAfterAnswers !== undefined) {
      formParams.append(
        "question[text_after_answers]",
        requestParameters.questionTextAfterAnswers as any
      );
    }

    /* tslint:disable */
    const response = await this.request(
      {
        path: `/v1/courses/{course_id}/quizzes/{quiz_id}/questions`
          .replace(
            `{${"course_id"}}`,
            encodeURIComponent(String(requestParameters.courseId))
          )
          .replace(
            `{${"quiz_id"}}`,
            encodeURIComponent(String(requestParameters.quizId))
          ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuizQuestionFromJSON(jsonValue)
    );
  }
}
