import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grow,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCourseMutation } from "../../../API";
import { withCourse } from "../../../components/withCourse";
import WizardPageTitle from "../../../components/WizardPageTitle";
import { Course } from "../../../graphql/API";

function AssessmentSettings({ course }: { course: Course }) {
  const { id, assessmentSettings } = course;
  const navigate = useNavigate();
  const updateMutation = useCourseMutation(id);
  const [draft, setDraft] = React.useState({
    hasMultipleChoiceQuizzes:
      assessmentSettings?.hasMultipleChoiceQuizzes ?? false,
    hasDiscussions: assessmentSettings?.hasDiscussions ?? false,
    quizFrequencyInWeeks: assessmentSettings?.quizFrequencyInWeeks ?? 2,
    quizNumberOfQuestions: assessmentSettings?.quizNumberOfQuestions ?? 6,
    discussionFrequencyInWeeks:
      assessmentSettings?.discussionFrequencyInWeeks ?? 1,
  });

  return (
    <Box>
      <WizardPageTitle title="Assessments" />
      <Typography variant="body1" gutterBottom>
        Let's define what types of assessments we want. Right now we can only do{" "}
        <strong>multiple choice quizzes</strong>.
      </Typography>
      <Box
        component="form"
        onSubmit={async (event) => {
          event.preventDefault();
          await updateMutation.mutateAsync({
            id,
            assessmentSettings: {
              ...draft,
            },
          });

          navigate(`/course/${id}/step-6`);
        }}
      >
        <FormGroup>
          <Stack spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={draft.hasMultipleChoiceQuizzes}
                  onChange={(_, checked) =>
                    setDraft({
                      ...draft,
                      hasMultipleChoiceQuizzes: checked,
                    })
                  }
                />
              }
              label="Do you want multiple choice quizzes?"
            />
            {draft.hasMultipleChoiceQuizzes && (
              <Grow in>
                <TextField
                  label="How often do you want the quizzes?"
                  type="number"
                  helperText={`There will be a quiz every ${draft.quizFrequencyInWeeks} week(s)`}
                  value={draft.quizFrequencyInWeeks}
                  inputProps={{ min: 0, max: 10 }}
                  onChange={(e) =>
                    setDraft({
                      ...draft,
                      quizFrequencyInWeeks: Number(e.target.value),
                    })
                  }
                />
              </Grow>
            )}
            {draft.hasMultipleChoiceQuizzes && (
              <Grow in>
                <TextField
                  label="How many questions should the quiz have?"
                  type="number"
                  value={draft.quizNumberOfQuestions}
                  inputProps={{ min: 1, max: 20 }}
                  onChange={(e) =>
                    setDraft({
                      ...draft,
                      quizNumberOfQuestions: Number(e.target.value),
                    })
                  }
                />
              </Grow>
            )}
            {/*
							<FormControlLabel
								control={
									<Checkbox
										checked={draft.hasDiscussions}
										onChange={(_, checked) =>
											setDraft({
												...draft,
												hasDiscussions: checked,
											})
										}
									/>
								}
								label="Do you want discussion topics?"
							/>
            */}
            {/*draft.hasDiscussions && (
              <Grow in>
                <TextField
                  label="How often do you want the discussions topics?"
                  type="number"
                  helperText={`There will be a discussion topic every ${draft.discussionFrequencyInWeeks} week(s)`}
                  value={draft.discussionFrequencyInWeeks}
                  inputProps={{ min: 0, max: 10 }}
                  onChange={(e) =>
                    setDraft({
                      ...draft,
                      discussionFrequencyInWeeks: Number(e.target.value),
                    })
                  }
                />
              </Grow>
            )*/}
          </Stack>
        </FormGroup>
        <LoadingButton
          sx={{ mt: 2 }}
          variant="outlined"
          type="submit"
          loading={updateMutation.isLoading}
        >
          Save and Next
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default withCourse(AssessmentSettings);
