/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      courseLevel
      studentSkillLevel
      lengthInWeeks
      courseObjectives {
        objectiveDescription
        objectiveReasoning
      }
      topics {
        topicDescription
        topicConcepts
      }
      assessmentSettings {
        hasMultipleChoiceQuizzes
        hasDiscussions
        quizFrequencyInWeeks
        quizNumberOfQuestions
        discussionFrequencyInWeeks
      }
      weeks {
        weekTitle
        conceptsCoveredDuringTheWeek
        weekReasoning
        suggestedLearningActivities
      }
      quizzes {
        quizHeldInWeek
        conceptsCoveredInTheQuiz
        courseObjectivesCoveredByTheQuiz
        quizQuestions {
          question
          multipleChoiceAnswers
        }
      }
      hasBeenExportedToCanvas
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        courseLevel
        studentSkillLevel
        lengthInWeeks
        courseObjectives {
          objectiveDescription
          objectiveReasoning
        }
        topics {
          topicDescription
          topicConcepts
        }
        assessmentSettings {
          hasMultipleChoiceQuizzes
          hasDiscussions
          quizFrequencyInWeeks
          quizNumberOfQuestions
          discussionFrequencyInWeeks
        }
        weeks {
          weekTitle
          conceptsCoveredDuringTheWeek
          weekReasoning
          suggestedLearningActivities
        }
        quizzes {
          quizHeldInWeek
          conceptsCoveredInTheQuiz
          courseObjectivesCoveredByTheQuiz
        }
        hasBeenExportedToCanvas
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      oauth2_client_secret
      test
      createdAt
      updatedAt
    }
  }
`;
export const listSettingss = /* GraphQL */ `
  query ListSettingss(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        oauth2_client_secret
        test
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
