import { useState } from "react";

/**
 * React hook that is useful when you have an uncontrolled component
 * but the onChange method for it is asynchronous (e.g. calls an API)
 *
 * @param initialValue - This should be the value of the uncontrolled component
 * @param onChange - This should be the onChange method of the uncontrolled component
 * @returns A tuple containing the value and the new onChange method
 */
export function useAsyncState<T>(
  initialValue: T,
  onChange: (value: T) => Promise<void>
) {
  const [value, setValue] = useState(initialValue);
  async function onAsyncChange(value: T) {
    setValue(value);
    await onChange(value);
  }
  return [value, onAsyncChange] as const;
}
