import { LanguageModelFunction, LanguageModelMessage } from "../../../API";
import { Course } from "../../../graphql/API";
import topicsSchema from "../../../language-model-schemas/topics.json";

export const defaultPrompt =
  "Can you please suggest some topics for my course?";

export function getConversationContext(course: Course): LanguageModelMessage[] {
  const extraContextAboutTopics = `I have these existing topics so far, given here as a JSON object in triple quotes """${JSON.stringify(
    course.topics
  )}"""`;

  return [
    {
      role: "system",
      content:
        "You are a learning designer helping design a course for a university in Australia.",
    },
    {
      role: "user",
      content: `Hi, can you help me design a course called '${
        course.name
      }'? The course will be '${
        course.lengthInWeeks
      }' weeks long, and is aimed at ${course.studentSkillLevel} ${
        course.courseLevel
      } students. The course objectives are given as a JSON object here in triple quotes """${JSON.stringify(
        course.courseObjectives
      )}""". I'm at the section where I'm deciding on what main topics the course will cover. 
				The number of topics does not need to be the same as the number of weeks. ${
          course.topics.length > 0 ? extraContextAboutTopics : ""
        }`,
    },
    {
      role: "assistant",
      content: `Sure thing, how can I help?`,
    },
  ];
}

export const suggestTopicsSchema: LanguageModelFunction[] = [
  {
    name: "suggest_topics",
    description: "Suggest a list of topics and concepts for a course",
    parameters: {
      type: "object",
      properties: {
        response: topicsSchema,
      },
      required: ["response"],
    },
  },
];

export const updateTopicSchema: LanguageModelFunction[] = [
  {
    name: "update_topic",
    description: "Update a topic in the list of topics",
    parameters: {
      type: "object",
      properties: {
        response: topicsSchema.items,
      },
      required: ["response"],
    },
  },
];
