/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CompletionRequirement } from "./CompletionRequirement";
import {
  CompletionRequirementFromJSON,
  CompletionRequirementFromJSONTyped,
  CompletionRequirementToJSON,
} from "./CompletionRequirement";
import type { ContentDetails } from "./ContentDetails";
import {
  ContentDetailsFromJSON,
  ContentDetailsFromJSONTyped,
  ContentDetailsToJSON,
} from "./ContentDetails";

/**
 *
 * @export
 * @interface ModuleItem
 */
export interface ModuleItem {
  /**
   *
   * @type {CompletionRequirement}
   * @memberof ModuleItem
   */
  completionRequirement?: CompletionRequirement;
  /**
   *
   * @type {ContentDetails}
   * @memberof ModuleItem
   */
  contentDetails?: ContentDetails;
  /**
   * the id of the object referred to applies to 'File', 'Discussion', 'Assignment', 'Quiz', 'ExternalTool' types
   * @type {number}
   * @memberof ModuleItem
   */
  contentId?: number;
  /**
   * (only for 'ExternalUrl' and 'ExternalTool' types) external url that the item points to
   * @type {string}
   * @memberof ModuleItem
   */
  externalUrl?: string;
  /**
   * link to the item in Canvas
   * @type {string}
   * @memberof ModuleItem
   */
  htmlUrl?: string;
  /**
   * the unique identifier for the module item
   * @type {number}
   * @memberof ModuleItem
   */
  id?: number;
  /**
   * 0-based indent level; module items may be indented to show a hierarchy
   * @type {number}
   * @memberof ModuleItem
   */
  indent?: number;
  /**
   * the id of the Module this item appears in
   * @type {number}
   * @memberof ModuleItem
   */
  moduleId?: number;
  /**
   * (only for 'ExternalTool' type) whether the external tool opens in a new tab
   * @type {boolean}
   * @memberof ModuleItem
   */
  newTab?: boolean;
  /**
   * (only for 'Page' type) unique locator for the linked wiki page
   * @type {string}
   * @memberof ModuleItem
   */
  pageUrl?: string;
  /**
   * the position of this item in the module (1-based)
   * @type {number}
   * @memberof ModuleItem
   */
  position?: number;
  /**
   * (Optional) Whether this module item is published. This field is present only if the caller has permission to view unpublished items.
   * @type {boolean}
   * @memberof ModuleItem
   */
  published?: boolean;
  /**
   * the title of this item
   * @type {string}
   * @memberof ModuleItem
   */
  title?: string;
  /**
   * the type of object referred to one of 'File', 'Page', 'Discussion', 'Assignment', 'Quiz', 'SubHeader', 'ExternalUrl', 'ExternalTool'
   * @type {string}
   * @memberof ModuleItem
   */
  type?: string;
  /**
   * (Optional) link to the Canvas API object, if applicable
   * @type {string}
   * @memberof ModuleItem
   */
  url?: string;
}

/**
 * Check if a given object implements the ModuleItem interface.
 */
export function instanceOfModuleItem(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ModuleItemFromJSON(json: any): ModuleItem {
  return ModuleItemFromJSONTyped(json, false);
}

export function ModuleItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ModuleItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completionRequirement: !exists(json, "completion_requirement")
      ? undefined
      : CompletionRequirementFromJSON(json["completion_requirement"]),
    contentDetails: !exists(json, "content_details")
      ? undefined
      : ContentDetailsFromJSON(json["content_details"]),
    contentId: !exists(json, "content_id") ? undefined : json["content_id"],
    externalUrl: !exists(json, "external_url")
      ? undefined
      : json["external_url"],
    htmlUrl: !exists(json, "html_url") ? undefined : json["html_url"],
    id: !exists(json, "id") ? undefined : json["id"],
    indent: !exists(json, "indent") ? undefined : json["indent"],
    moduleId: !exists(json, "module_id") ? undefined : json["module_id"],
    newTab: !exists(json, "new_tab") ? undefined : json["new_tab"],
    pageUrl: !exists(json, "page_url") ? undefined : json["page_url"],
    position: !exists(json, "position") ? undefined : json["position"],
    published: !exists(json, "published") ? undefined : json["published"],
    title: !exists(json, "title") ? undefined : json["title"],
    type: !exists(json, "type") ? undefined : json["type"],
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function ModuleItemToJSON(value?: ModuleItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completion_requirement: CompletionRequirementToJSON(
      value.completionRequirement
    ),
    content_details: ContentDetailsToJSON(value.contentDetails),
    content_id: value.contentId,
    external_url: value.externalUrl,
    html_url: value.htmlUrl,
    id: value.id,
    indent: value.indent,
    module_id: value.moduleId,
    new_tab: value.newTab,
    page_url: value.pageUrl,
    position: value.position,
    published: value.published,
    title: value.title,
    type: value.type,
    url: value.url,
  };
}
