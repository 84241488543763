/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { LockInfo } from "./LockInfo";
import {
  LockInfoFromJSON,
  LockInfoFromJSONTyped,
  LockInfoToJSON,
} from "./LockInfo";
import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface Page
 */
export interface Page {
  /**
   * the page content, in HTML (present when requesting a single page; omitted when listing pages)
   * @type {string}
   * @memberof Page
   */
  body?: string;
  /**
   * the creation date for the page
   * @type {Date}
   * @memberof Page
   */
  createdAt?: Date;
  /**
   * roles allowed to edit the page; comma-separated list comprising a combination of 'teachers', 'students', 'members', and/or 'public' if not supplied, course defaults are used
   * @type {string}
   * @memberof Page
   */
  editingRoles?: string;
  /**
   * whether this page is the front page for the wiki
   * @type {boolean}
   * @memberof Page
   */
  frontPage?: boolean;
  /**
   * (DEPRECATED) whether this page is hidden from students (note: this is always reflected as the inverse of the published value)
   * @type {boolean}
   * @memberof Page
   */
  hideFromStudents?: boolean;
  /**
   *
   * @type {User}
   * @memberof Page
   */
  lastEditedBy?: User;
  /**
   * (Optional) An explanation of why this is locked for the user. Present when locked_for_user is true.
   * @type {string}
   * @memberof Page
   */
  lockExplanation?: string;
  /**
   *
   * @type {LockInfo}
   * @memberof Page
   */
  lockInfo?: LockInfo;
  /**
   * Whether or not this is locked for the user.
   * @type {boolean}
   * @memberof Page
   */
  lockedForUser?: boolean;
  /**
   * the ID of the page
   * @type {number}
   * @memberof Page
   */
  pageId?: number;
  /**
   * scheduled publication date for this page
   * @type {Date}
   * @memberof Page
   */
  publishAt?: Date;
  /**
   * whether the page is published (true) or draft state (false).
   * @type {boolean}
   * @memberof Page
   */
  published?: boolean;
  /**
   * the title of the page
   * @type {string}
   * @memberof Page
   */
  title?: string;
  /**
   * the date the page was last updated
   * @type {Date}
   * @memberof Page
   */
  updatedAt?: Date;
  /**
   * the unique locator for the page
   * @type {string}
   * @memberof Page
   */
  url?: string;
}

/**
 * Check if a given object implements the Page interface.
 */
export function instanceOfPage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PageFromJSON(json: any): Page {
  return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Page {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    body: !exists(json, "body") ? undefined : json["body"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    editingRoles: !exists(json, "editing_roles")
      ? undefined
      : json["editing_roles"],
    frontPage: !exists(json, "front_page") ? undefined : json["front_page"],
    hideFromStudents: !exists(json, "hide_from_students")
      ? undefined
      : json["hide_from_students"],
    lastEditedBy: !exists(json, "last_edited_by")
      ? undefined
      : UserFromJSON(json["last_edited_by"]),
    lockExplanation: !exists(json, "lock_explanation")
      ? undefined
      : json["lock_explanation"],
    lockInfo: !exists(json, "lock_info")
      ? undefined
      : LockInfoFromJSON(json["lock_info"]),
    lockedForUser: !exists(json, "locked_for_user")
      ? undefined
      : json["locked_for_user"],
    pageId: !exists(json, "page_id") ? undefined : json["page_id"],
    publishAt: !exists(json, "publish_at")
      ? undefined
      : new Date(json["publish_at"]),
    published: !exists(json, "published") ? undefined : json["published"],
    title: !exists(json, "title") ? undefined : json["title"],
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function PageToJSON(value?: Page | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    body: value.body,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    editing_roles: value.editingRoles,
    front_page: value.frontPage,
    hide_from_students: value.hideFromStudents,
    last_edited_by: UserToJSON(value.lastEditedBy),
    lock_explanation: value.lockExplanation,
    lock_info: LockInfoToJSON(value.lockInfo),
    locked_for_user: value.lockedForUser,
    page_id: value.pageId,
    publish_at:
      value.publishAt === undefined ? undefined : value.publishAt.toISOString(),
    published: value.published,
    title: value.title,
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    url: value.url,
  };
}
