/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ModuleItem } from "./ModuleItem";
import {
  ModuleItemFromJSON,
  ModuleItemFromJSONTyped,
  ModuleItemToJSON,
} from "./ModuleItem";

/**
 *
 * @export
 * @interface Module
 */
export interface Module {
  /**
   * the date the calling user completed the module (Optional; present only if the caller is a student or if the optional parameter 'student_id' is included)
   * @type {Date}
   * @memberof Module
   */
  completedAt?: Date;
  /**
   * the unique identifier for the module
   * @type {number}
   * @memberof Module
   */
  id?: number;
  /**
   * The contents of this module, as an array of Module Items. (Present only if requested via include[]=items AND the module is not deemed too large by Canvas.)
   * @type {Array<ModuleItem>}
   * @memberof Module
   */
  items?: Array<ModuleItem>;
  /**
   * The number of items in the module
   * @type {number}
   * @memberof Module
   */
  itemsCount?: number;
  /**
   * The API URL to retrive this module's items
   * @type {string}
   * @memberof Module
   */
  itemsUrl?: string;
  /**
   * the name of this module
   * @type {string}
   * @memberof Module
   */
  name?: string;
  /**
   * the position of this module in the course (1-based)
   * @type {number}
   * @memberof Module
   */
  position?: number;
  /**
   * IDs of Modules that must be completed before this one is unlocked
   * @type {Array<number>}
   * @memberof Module
   */
  prerequisiteModuleIds?: Array<number>;
  /**
   * if the student's final grade for the course should be published to the SIS upon completion of this module
   * @type {boolean}
   * @memberof Module
   */
  publishFinalGrade?: boolean;
  /**
   * (Optional) Whether this module is published. This field is present only if the caller has permission to view unpublished modules.
   * @type {boolean}
   * @memberof Module
   */
  published?: boolean;
  /**
   * Whether module items must be unlocked in order
   * @type {boolean}
   * @memberof Module
   */
  requireSequentialProgress?: boolean;
  /**
   * The state of this Module for the calling user one of 'locked', 'unlocked', 'started', 'completed' (Optional; present only if the caller is a student or if the optional parameter 'student_id' is included)
   * @type {string}
   * @memberof Module
   */
  state?: string;
  /**
   * (Optional) the date this module will unlock
   * @type {Date}
   * @memberof Module
   */
  unlockAt?: Date;
  /**
   * the state of the module: 'active', 'deleted'
   * @type {string}
   * @memberof Module
   */
  workflowState?: string;
}

/**
 * Check if a given object implements the Module interface.
 */
export function instanceOfModule(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ModuleFromJSON(json: any): Module {
  return ModuleFromJSONTyped(json, false);
}

export function ModuleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Module {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completedAt: !exists(json, "completed_at")
      ? undefined
      : new Date(json["completed_at"]),
    id: !exists(json, "id") ? undefined : json["id"],
    items: !exists(json, "items")
      ? undefined
      : (json["items"] as Array<any>).map(ModuleItemFromJSON),
    itemsCount: !exists(json, "items_count") ? undefined : json["items_count"],
    itemsUrl: !exists(json, "items_url") ? undefined : json["items_url"],
    name: !exists(json, "name") ? undefined : json["name"],
    position: !exists(json, "position") ? undefined : json["position"],
    prerequisiteModuleIds: !exists(json, "prerequisite_module_ids")
      ? undefined
      : json["prerequisite_module_ids"],
    publishFinalGrade: !exists(json, "publish_final_grade")
      ? undefined
      : json["publish_final_grade"],
    published: !exists(json, "published") ? undefined : json["published"],
    requireSequentialProgress: !exists(json, "require_sequential_progress")
      ? undefined
      : json["require_sequential_progress"],
    state: !exists(json, "state") ? undefined : json["state"],
    unlockAt: !exists(json, "unlock_at")
      ? undefined
      : new Date(json["unlock_at"]),
    workflowState: !exists(json, "workflow_state")
      ? undefined
      : json["workflow_state"],
  };
}

export function ModuleToJSON(value?: Module | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completed_at:
      value.completedAt === undefined
        ? undefined
        : value.completedAt.toISOString(),
    id: value.id,
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(ModuleItemToJSON),
    items_count: value.itemsCount,
    items_url: value.itemsUrl,
    name: value.name,
    position: value.position,
    prerequisite_module_ids: value.prerequisiteModuleIds,
    publish_final_grade: value.publishFinalGrade,
    published: value.published,
    require_sequential_progress: value.requireSequentialProgress,
    state: value.state,
    unlock_at:
      value.unlockAt === undefined ? undefined : value.unlockAt.toISOString(),
    workflow_state: value.workflowState,
  };
}
