import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Grow,
  List,
  ListItem,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import AiAssistantButton from "../../../components/AiAssistantButton";
import { Course, Topic } from "../../../graphql/API";
import { updateTopicSchema, getConversationContext } from "./prompts";

interface TopicCardProps {
  value: Topic;
  onUpdate: (value: Topic) => Promise<void>;
  onDelete: () => void;
  onCardClick: Function;
  isSelected: boolean;
  isLoading: boolean;
  course: Course;
}

export function TopicCard(props: TopicCardProps) {
  const { value, onCardClick, isSelected, isLoading } = props;
  const [cardState, setCardState] = React.useState<"displaying" | "editing">(
    "displaying"
  );
  const [draftTopic, setDraftTopic] = React.useState<Topic>(value);
  const cardReference = useRef(null);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          margin: "10px",
        }}
        ref={cardReference}
      >
        <Grow in={true}>
          {cardState === "displaying" ? (
            <CardActionArea onClick={() => onCardClick()}>
              <CardContent
                sx={{
                  border: isSelected
                    ? "3px solid lightblue"
                    : "3px solid white",
                }}
              >
                <Typography variant="body1">
                  {value.topicDescription}
                </Typography>
                <List
                  dense
                  disablePadding
                  sx={{ listStyleType: "disc", pl: 4 }}
                >
                  {value.topicConcepts.map((concept, index) => (
                    <ListItem sx={{ display: "list-item" }} key={index}>
                      {concept}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </CardActionArea>
          ) : (
            // Editing
            <Stack spacing={2} p={3} alignItems="start">
              <TextField
                id="outlined-basic"
                fullWidth
                label="Description"
                variant="outlined"
                value={draftTopic.topicDescription}
                onChange={(event) => {
                  setDraftTopic({
                    ...draftTopic,
                    topicDescription: event.target.value,
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                multiline
                fullWidth
                label="Concepts"
                variant="outlined"
                value={draftTopic.topicConcepts.join("\n")}
                onChange={(event) => {
                  setDraftTopic({
                    ...draftTopic,
                    topicConcepts: event.target.value.split("\n"),
                  });
                }}
              />
              <LoadingButton
                variant="contained"
                startIcon={<DoneIcon />}
                loading={isLoading}
                onClick={async () => {
                  await props.onUpdate(draftTopic);
                  setCardState("displaying");
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          )}
        </Grow>
      </Card>
      <Popper
        open={isSelected && cardState === "displaying"}
        anchorEl={cardReference.current}
        placement="bottom-end"
      >
        <Card variant="elevation">
          <Grid container spacing={2} p={1}>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                color="info"
                onClick={() => {
                  setCardState("editing");
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <AiAssistantButton
                conversationContext={getConversationContext(
                  props.course
                ).concat([
                  {
                    role: "user",
                    content: `I'm currently editing this topic, given here as a JSON object in triple quotes """${JSON.stringify(
                      value
                    )}"""`,
                  },
                  {
                    role: "assistant",
                    content: `Ok, how would you like to change this topic?`,
                  },
                ])}
                functionsSchema={updateTopicSchema}
                placeholder={"How would you like to change this topic?"}
                onAssistantResponse={async (data: any) => {
                  await props.onUpdate(data);
									setCardState("displaying");
                }}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                startIcon={<DeleteIcon />}
                color="error"
                loading={isLoading}
                onClick={() => {
                  props.onDelete();
                }}
              >
                Delete
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </Popper>
    </>
  );
}
