/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Term
 */
export interface Term {
  /**
   *
   * @type {Date}
   * @memberof Term
   */
  endAt?: Date;
  /**
   *
   * @type {number}
   * @memberof Term
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Term
   */
  name?: string;
  /**
   *
   * @type {Date}
   * @memberof Term
   */
  startAt?: Date;
}

/**
 * Check if a given object implements the Term interface.
 */
export function instanceOfTerm(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TermFromJSON(json: any): Term {
  return TermFromJSONTyped(json, false);
}

export function TermFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Term {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    endAt: !exists(json, "end_at") ? undefined : new Date(json["end_at"]),
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    startAt: !exists(json, "start_at") ? undefined : new Date(json["start_at"]),
  };
}

export function TermToJSON(value?: Term | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    end_at: value.endAt === undefined ? undefined : value.endAt.toISOString(),
    id: value.id,
    name: value.name,
    start_at:
      value.startAt === undefined ? undefined : value.startAt.toISOString(),
  };
}
