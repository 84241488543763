import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCourseMutation } from "../../../API";
import AiAssistantButton from "../../../components/AiAssistantButton";
import { withCourse } from "../../../components/withCourse";
import WizardPageTitle from "../../../components/WizardPageTitle";
import { Course } from "../../../graphql/API";
import {
  defaultPrompt,
  getConversationContext,
  suggestWeeklyStructureSchema,
} from "./prompts";
import WeekAccordionList from "./WeekAccordionList";

function WeeklyStructure({ course }: { course: Course }) {
  const { id, weeks } = course;
  const navigate = useNavigate();
  const updateWeekMutation = useCourseMutation(id);

  // We have 2 of the same mutation because we want to show different "isLoading" states for adding a week vs updating a week
  const createWeekMutation = useCourseMutation(id);

  function addWeek() {
    createWeekMutation.mutate({
      id,
      weeks: [
        ...course.weeks,
        {
          weekTitle: "New Week",
          conceptsCoveredDuringTheWeek: [],
          weekReasoning:
            "Why is it important that students learn this content?",
          suggestedLearningActivities: [],
        },
      ],
    });
  }

  return (
    <Box>
      <WizardPageTitle title="Weekly Structure" />
      <Typography variant="body1" gutterBottom>
        Lets map out what each week will look like for{" "}
        <em>{course.name}</em>.
      </Typography>
      <Stack direction="row" spacing={2} mt={2} mb={2}>
        <LoadingButton
          variant="contained"
          startIcon={<AddIcon />}
          color="info"
          onClick={addWeek}
          loading={createWeekMutation.isLoading}
        >
          <span>Add</span>
        </LoadingButton>
        <AiAssistantButton
          conversationContext={getConversationContext(course)}
          defaultPrompt={defaultPrompt}
          functionsSchema={suggestWeeklyStructureSchema(course)}
          onAssistantResponse={(data: any) => {
						console.log(data);
            updateWeekMutation.mutate({
              id,
              weeks: data,
            });
          }}
        />
      </Stack>
      <WeekAccordionList
        value={weeks}
        course={course} // Needed so the AI assistant has access to the course
        isLoading={updateWeekMutation.isLoading}
        onChange={(newWeeks) => {
          return updateWeekMutation.mutateAsync({
            id,
            weeks: [...newWeeks],
          });
        }}
      />

      <Stack
        spacing={2}
        alignItems="start"
        justifyContent="space-around"
        mt={2}
      >
        {weeks.length !== course.lengthInWeeks && (
          <Alert severity="error">
            This course needs to have {course.lengthInWeeks} weeks, but you have
            created {weeks.length}.
          </Alert>
        )}
        <Button
          sx={{ mt: 2 }}
          variant="outlined"
          onClick={() => {
          	navigate(`/course/${id}/step-5`);
          }}
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
}

export default withCourse(WeeklyStructure);
