import React from "react";
import DragAndDropList from "../../../components/DragAndDropList";
import { Course, CourseObjective } from "../../../graphql/API";
import { useAsyncState } from "../../../hooks/useAsyncState";
import { CourseObjectiveCard } from "./CourseObjectiveCard";

interface CourseObjectiveCardListProps {
  value: CourseObjective[];
  onChange: (value: CourseObjective[]) => Promise<void>;
  isLoading: boolean;
  course: Course;
}

export function CourseObjectiveCardList(props: CourseObjectiveCardListProps) {
  // This litle bit of internal state allows for smoother UI updates
  const [value, onChange] = useAsyncState(props.value, props.onChange);

  const [selectedCourseObjective, setSelectedCourseObjective] =
    React.useState<Number | null>(null);

  return (
    <DragAndDropList
      droppableId="course-objective-list"
      value={value}
      onChange={async (value) => {
        setSelectedCourseObjective(null);
        await onChange(value);
      }}
    >
      {(courseObjective, index) => (
        <CourseObjectiveCard
          key={index + JSON.stringify(courseObjective)} // Needed so that the card is re-rendered when the course objective changes
          value={courseObjective}
          index={index}
          onUpdate={async (newCourseObjective) => {
            setSelectedCourseObjective(null);
            const newCourseObjectives = [...value];
            newCourseObjectives[index] = newCourseObjective;
            await onChange(newCourseObjectives);
          }}
          onDelete={async () => {
            setSelectedCourseObjective(null);
            const newCourseObjectives = [...value];
            newCourseObjectives.splice(index, 1); // Remove the element at index
            await onChange(newCourseObjectives);
          }}
          isSelected={selectedCourseObjective === index}
          isLoading={props.isLoading}
          onCardClick={() => {
            if (selectedCourseObjective !== index)
              setSelectedCourseObjective(index);
            else setSelectedCourseObjective(null);
          }}
          course={props.course}
        />
      )}
    </DragAndDropList>
  );
}

export default CourseObjectiveCardList;
