/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LockInfo
 */
export interface LockInfo {
  /**
   * Asset string for the object causing the lock
   * @type {string}
   * @memberof LockInfo
   */
  assetString?: string;
  /**
   * (Optional) Context module causing the lock.
   * @type {string}
   * @memberof LockInfo
   */
  contextModule?: string;
  /**
   * (Optional) Time at which this was/will be locked. Must be after the due date.
   * @type {Date}
   * @memberof LockInfo
   */
  lockAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof LockInfo
   */
  manuallyLocked?: boolean;
  /**
   * (Optional) Time at which this was/will be unlocked. Must be before the due date.
   * @type {Date}
   * @memberof LockInfo
   */
  unlockAt?: Date;
}

/**
 * Check if a given object implements the LockInfo interface.
 */
export function instanceOfLockInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function LockInfoFromJSON(json: any): LockInfo {
  return LockInfoFromJSONTyped(json, false);
}

export function LockInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LockInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assetString: !exists(json, "asset_string")
      ? undefined
      : json["asset_string"],
    contextModule: !exists(json, "context_module")
      ? undefined
      : json["context_module"],
    lockAt: !exists(json, "lock_at") ? undefined : new Date(json["lock_at"]),
    manuallyLocked: !exists(json, "manually_locked")
      ? undefined
      : json["manually_locked"],
    unlockAt: !exists(json, "unlock_at")
      ? undefined
      : new Date(json["unlock_at"]),
  };
}

export function LockInfoToJSON(value?: LockInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    asset_string: value.assetString,
    context_module: value.contextModule,
    lock_at:
      value.lockAt === undefined ? undefined : value.lockAt.toISOString(),
    manually_locked: value.manuallyLocked,
    unlock_at:
      value.unlockAt === undefined ? undefined : value.unlockAt.toISOString(),
  };
}
