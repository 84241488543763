import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fetchCourse, useCourseMutation } from "../../API";
import { withCourse } from "../../components/withCourse";
import { Course } from "../../graphql/API";

// TODO: Rewrite this to properly use React Query
function CourseDetails({ course }: { course: Course }) {
  const { id } = course;
  const navigate = useNavigate();
  const updateCourseMutation = useCourseMutation(id);
  const [draft, setDraft] = React.useState(course);

  return (
    <div style={{ width: "100%" }}>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <h1>Course Details</h1>
        <Button
          onClick={() => {
            navigate(`/`);
          }}
        >
          Exit
        </Button>
      </Flex>
      <div style={{ width: "50%" }}>
        <Grid
          as="form"
          rowGap="15px"
          columnGap="15px"
          paddingLeft="20px"
          onSubmit={async (event) => {
            event.preventDefault();
            await updateCourseMutation.mutateAsync({
              id,
              name: draft.name,
              courseLevel: draft.courseLevel,
              studentSkillLevel: draft.studentSkillLevel,
              lengthInWeeks: draft.lengthInWeeks,
              hasBeenExportedToCanvas: false, // TODO Hack: I did this just as a way to reset
            });
            await fetchCourse(id); // TODO Hack: remove me
            navigate(`/course/${id}/step-2`);
          }}
        >
          <TextField
            label="What is the name of the course?"
            placeholder="E.g. Introduction to Data Science"
            isRequired={true}
            isReadOnly={false}
            value={draft.name || ""}
            onChange={(e) => {
              let { value } = e.target;
              setDraft({ ...draft, name: value });
            }}
          ></TextField>
          <TextField
            label="How many weeks will the course be?"
            isRequired={true}
            isReadOnly={false}
            type="number"
            step="any"
            value={draft.lengthInWeeks || ""}
            onChange={(e) => {
              let value = isNaN(parseInt(e.target.value))
                ? undefined
                : parseInt(e.target.value);
              setDraft({ ...draft, lengthInWeeks: value });
            }}
          ></TextField>
          <SelectField
            label="What is the course level?"
            placeholder="Select a level..."
            value={draft.courseLevel || ""}
            isRequired={true}
            onChange={(e) => {
              let { value } = e.target;
              setDraft({ ...draft, courseLevel: value });
            }}
          >
            <option value="Bachelors">Bachelors</option>
            <option value="Masters">Masters</option>
            <option value="Diploma">Diploma</option>
          </SelectField>
          <SelectField
            label="What is the student skill level?"
            placeholder="Select a level..."
            value={draft.studentSkillLevel || ""}
            isRequired={true}
            onChange={(e) => {
              let { value } = e.target;
              setDraft({ ...draft, studentSkillLevel: value });
            }}
          >
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </SelectField>
          <div>
            <Button
              children="Save and Next"
              type="submit"
              variation="primary"
            ></Button>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default withCourse(CourseDetails);
