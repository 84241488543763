import { LanguageModelFunction, LanguageModelMessage } from "../../../API";
import { Course } from "../../../graphql/API";
import weekSchema from "../../../language-model-schemas/week.json";

export const defaultPrompt = "Can you please suggest a weekly structure?";

export function getConversationContext(course: Course): LanguageModelMessage[] {
  const extraContextAboutTheStructure = `I have this existing weekly structure so far, given here as a JSON object in triple quotes """${JSON.stringify(
    course.weeks
  )}""".`;
  return [
    {
      role: "system",
      content: `You are a learning designer helping design a course for a university in Australia. You always return incredibly descriptive responses. You always double check and make sure that the returned repsonse is in the absolute correct JSON schema format.`,
    },
    {
      role: "user",
      content: `Hi, can you help me design a course called '${
        course.name
      }'? The course will be '${
        course.lengthInWeeks
      }' weeks long, and is aimed at ${course.studentSkillLevel} ${
        course.courseLevel
      } students. The course objectives are given as a JSON object here in triple quotes """${JSON.stringify(
        course.courseObjectives
      )}""". The course topics and concepts are given as a JSON object here in triple quotes """${JSON.stringify(
        course.topics
      )}""". ${
        course.weeks.length > 0 ? extraContextAboutTheStructure : ""
      } Can you make sure there are exactly ${course.lengthInWeeks} weeks in 
				your response, and that all the concepts are covered? Do not include "Week 1", "Week 2", ... in the week titles.`,
    },
  ];
}

export function suggestWeeklyStructureSchema(
  course: Course
): LanguageModelFunction[] {
  const schema = [
    {
      name: "suggest_weekly_structure",
      description: "Suggest a weekly structure for a course",
      parameters: {
        type: "object",
        properties: {
          response: weekSchema,
        },
        required: ["response"],
      },
    },
  ];
  // Make sure we've got the right number of weeks
  schema[0].parameters.properties.response.minItems =
    course.lengthInWeeks || 10;
  schema[0].parameters.properties.response.maxItems =
    course.lengthInWeeks || 10;

  // Made sure all the concepts are covered
  schema[0].parameters.properties.response.items.properties.conceptsCoveredDuringTheWeek.items.enum =
    course.topics.map((topic) => topic.topicConcepts).flat();
  return schema;
}

export function improveWeekStructure(course: Course): LanguageModelFunction[] {
  const schema = [
    {
      name: "update_week",
      description: "Update a week in the list of weeks",
      parameters: {
        type: "object",
        properties: {
          response: weekSchema.items,
        },
        required: ["response"],
      },
    },
  ];

  // Made sure all the concepts are covered
  schema[0].parameters.properties.response.properties.conceptsCoveredDuringTheWeek.items.enum =
    course.topics.map((topic) => topic.topicConcepts).flat();
  return schema;
}
