import {
  Alert,
  Button,
  Flex,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createCanvasClient,
  createCourse,
  deleteCourse,
  fetchCourses,
} from "../API";
import Canvas from "../canvas-api";
import { Course } from "../graphql/API";

export default function Home() {
  const [courses, setCourses] = useState<Course[]>([]);
  const [canvasCourses, setCanvasCourses] = useState<Canvas.Course[]>([]);
  const [loggedInToCanvas, setLoggedInToCanvas] = useState(false);
  const navigate = useNavigate();
  // Get the base path of the current location
  const basePath = window.location.protocol + "//" + window.location.host;

  const refreshCourses = async () => {
    fetchCourses().then((courses) => setCourses(courses));
    try {
      const canvas_client = await createCanvasClient();
      const my_canvas_courses = await canvas_client.listYourCourses();
      setCanvasCourses(my_canvas_courses);
      setLoggedInToCanvas(true);
    } catch (e) {
      console.log(e);
      setLoggedInToCanvas(false);
    }
  };
  useEffect(() => {
    // Fetch the courses on page load
    refreshCourses();
  }, []);

  // List the courses in a table
  return (
    <Flex direction="row" justifyContent="space-between" gap="10rem">
      <div style={{ width: "50%" }}>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <h1>Your Draft Courses</h1>

          <Button
            variation="primary"
            onClick={() => {
              createCourse().then((course) =>
                navigate(`/course/${course.id}/step`)
              );
            }}
          >
            Create Course
          </Button>
        </Flex>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell as="th">Course Name</TableCell>
              <TableCell as="th">Course Length</TableCell>
              <TableCell as="th">Published to Canvas</TableCell>
              <TableCell as="th"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses
              .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
              .map((course, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {course.name ? course.name : <em>New Course</em>}
                  </TableCell>
                  <TableCell>
                    {course.lengthInWeeks !== null &&
                    course.lengthInWeeks !== undefined
                      ? `${course.lengthInWeeks} weeks`
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {course.hasBeenExportedToCanvas ? "Yes ✅" : "No ❌"}
                  </TableCell>
                  <TableCell>
                    <Flex direction="row" justifyContent="end">
                      <Button
                        onClick={() => {
                          navigate(`/course/${course.id}/step`);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variation="destructive"
                        onClick={() =>
                          deleteCourse(course.id).then(refreshCourses)
                        }
                      >
                        Delete
                      </Button>
                    </Flex>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div style={{ width: "50%" }}>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <h1>Your Canvas Courses</h1>

          <Flex direction="row">
            {loggedInToCanvas ? (
              <Alert variation="success">Logged in</Alert>
            ) : (
              <Alert variation="warning">Not logged in</Alert>
            )}
            <Button
              marginRight="medium"
              variation="primary"
              backgroundColor="green"
              onClick={() => {
                window.location.href = `https://canvas-dev.c3l.ai/login/oauth2/auth?client_id=10000000000006&response_type=code&redirect_uri=${basePath}/oauth2/response`;
              }}
            >
              Login to Canvas
            </Button>
          </Flex>
        </Flex>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell as="th">Course Name</TableCell>
              <TableCell as="th">Link to Canvas</TableCell>
              <TableCell as="th"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {canvasCourses.map((course, index) => (
              <TableRow key={index}>
                <TableCell>
                  {course.name ? course.name : <em>New Course</em>}
                </TableCell>
                <TableCell>
                  <Link href={`https://canvas-dev.c3l.ai/courses/${course.id}`}>
                    https://canvas-dev.c3l.ai/courses/{course.id}
                  </Link>
                </TableCell>
                <TableCell>
                  <Button
                    variation="destructive"
                    onClick={async () => {
                      const courseDeleteRequest: Canvas.DeleteConcludeCourseRequest =
                        {
                          id: `${course.id}`,
                          event: "delete",
                        };
                      const canvas_client = await createCanvasClient();
                      await canvas_client.deleteConcludeCourse(
                        courseDeleteRequest
                      );
                      await refreshCourses();
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Flex>
  );
}
