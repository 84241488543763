import "@aws-amplify/ui-react/styles.css";
import { Navigate } from "react-router-dom";
import { Course } from "../graphql/API";
import { withCourse } from "./withCourse";

// Note, these redirects will fail in production if the following settings aren't made
// in Amplify Studio > Rewrites and redirects
// https://docs.aws.amazon.com/amplify/latest/userguide/redirects.html#redirects-for-single-page-web-apps-spa
// https://github.com/aws-amplify/amplify-cli/issues/5047

// When rendered, this will redirect the user to the correct wizard step, based off the
// course object in the database.
function RedirectToCorrectStep({ course }: { course: Course }) {
  if (!course) {
    console.error("No course provided to RedirectToCorrectWizardStep");
    return <Navigate to="/" />;
  }
  if (!course.name || !course.lengthInWeeks) {
    return <Navigate to={`/course/${course.id}/step-1`} />;
  }
  if (!course.courseObjectives) {
    return <Navigate to={`/course/${course.id}/step-2`} />;
  }
  // TODO: Add rest of the steps here

  // Default
  return <Navigate to={`/course/${course.id}/step-1`} />;
}

export default withCourse(RedirectToCorrectStep);
