/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Module } from "./Module";
import { ModuleFromJSON, ModuleFromJSONTyped, ModuleToJSON } from "./Module";
import type { ModuleItemSequenceNode } from "./ModuleItemSequenceNode";
import {
  ModuleItemSequenceNodeFromJSON,
  ModuleItemSequenceNodeFromJSONTyped,
  ModuleItemSequenceNodeToJSON,
} from "./ModuleItemSequenceNode";

/**
 *
 * @export
 * @interface ModuleItemSequence
 */
export interface ModuleItemSequence {
  /**
   * an array containing one ModuleItemSequenceNode for each appearence of the asset in the module sequence (up to 10 total)
   * @type {Array<ModuleItemSequenceNode>}
   * @memberof ModuleItemSequence
   */
  items?: Array<ModuleItemSequenceNode>;
  /**
   * an array containing each Module referenced above
   * @type {Array<Module>}
   * @memberof ModuleItemSequence
   */
  modules?: Array<Module>;
}

/**
 * Check if a given object implements the ModuleItemSequence interface.
 */
export function instanceOfModuleItemSequence(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ModuleItemSequenceFromJSON(json: any): ModuleItemSequence {
  return ModuleItemSequenceFromJSONTyped(json, false);
}

export function ModuleItemSequenceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ModuleItemSequence {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: !exists(json, "items")
      ? undefined
      : (json["items"] as Array<any>).map(ModuleItemSequenceNodeFromJSON),
    modules: !exists(json, "modules")
      ? undefined
      : (json["modules"] as Array<any>).map(ModuleFromJSON),
  };
}

export function ModuleItemSequenceToJSON(
  value?: ModuleItemSequence | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(ModuleItemSequenceNodeToJSON),
    modules:
      value.modules === undefined
        ? undefined
        : (value.modules as Array<any>).map(ModuleToJSON),
  };
}
