/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AssignmentDate } from "./AssignmentDate";
import {
  AssignmentDateFromJSON,
  AssignmentDateFromJSONTyped,
  AssignmentDateToJSON,
} from "./AssignmentDate";
import type { LockInfo } from "./LockInfo";
import {
  LockInfoFromJSON,
  LockInfoFromJSONTyped,
  LockInfoToJSON,
} from "./LockInfo";
import type { QuizPermissions } from "./QuizPermissions";
import {
  QuizPermissionsFromJSON,
  QuizPermissionsFromJSONTyped,
  QuizPermissionsToJSON,
} from "./QuizPermissions";

/**
 *
 * @export
 * @interface Quiz
 */
export interface Quiz {
  /**
   * access code to restrict quiz access
   * @type {string}
   * @memberof Quiz
   */
  accessCode?: string;
  /**
   * list of due dates for the quiz
   * @type {Array<AssignmentDate>}
   * @memberof Quiz
   */
  allDates?: Array<AssignmentDate>;
  /**
   * how many times a student can take the quiz -1 = unlimited attempts
   * @type {number}
   * @memberof Quiz
   */
  allowedAttempts?: number;
  /**
   * Whether survey submissions will be kept anonymous (only applicable to 'graded_survey', 'survey' quiz types)
   * @type {boolean}
   * @memberof Quiz
   */
  anonymousSubmissions?: boolean;
  /**
   * the ID of the quiz's assignment group:
   * @type {number}
   * @memberof Quiz
   */
  assignmentGroupId?: number;
  /**
   * lock questions after answering? only valid if one_question_at_a_time=true
   * @type {boolean}
   * @memberof Quiz
   */
  cantGoBack?: boolean;
  /**
   * the description of the quiz
   * @type {string}
   * @memberof Quiz
   */
  description?: string;
  /**
   * when the quiz is due
   * @type {Date}
   * @memberof Quiz
   */
  dueAt?: Date;
  /**
   * prevent the students from seeing correct answers after the specified date has passed. only valid if show_correct_answers=true
   * @type {Date}
   * @memberof Quiz
   */
  hideCorrectAnswersAt?: Date;
  /**
   * let students see their quiz responses? possible values: null, 'always', 'until_after_last_attempt'
   * @type {string}
   * @memberof Quiz
   */
  hideResults?: string;
  /**
   * the HTTP/HTTPS URL to the quiz
   * @type {string}
   * @memberof Quiz
   */
  htmlUrl?: string;
  /**
   * the ID of the quiz
   * @type {number}
   * @memberof Quiz
   */
  id?: number;
  /**
   * IP address or range that quiz access is limited to
   * @type {string}
   * @memberof Quiz
   */
  ipFilter?: string;
  /**
   * when to lock the quiz
   * @type {Date}
   * @memberof Quiz
   */
  lockAt?: Date;
  /**
   * (Optional) An explanation of why this is locked for the user. Present when locked_for_user is true.
   * @type {string}
   * @memberof Quiz
   */
  lockExplanation?: string;
  /**
   *
   * @type {LockInfo}
   * @memberof Quiz
   */
  lockInfo?: LockInfo;
  /**
   * Whether or not this is locked for the user.
   * @type {boolean}
   * @memberof Quiz
   */
  lockedForUser?: boolean;
  /**
   * a url suitable for loading the quiz in a mobile webview.  it will persiste the headless session and, for quizzes in public courses, will force the user to login
   * @type {string}
   * @memberof Quiz
   */
  mobileUrl?: string;
  /**
   * show one question at a time?
   * @type {boolean}
   * @memberof Quiz
   */
  oneQuestionAtATime?: boolean;
  /**
   * prevent the students from seeing their results more than once (right after they submit the quiz)
   * @type {boolean}
   * @memberof Quiz
   */
  oneTimeResults?: boolean;
  /**
   *
   * @type {QuizPermissions}
   * @memberof Quiz
   */
  permissions?: QuizPermissions;
  /**
   * The total point value given to the quiz
   * @type {number}
   * @memberof Quiz
   */
  pointsPossible?: number;
  /**
   * A url that can be visited in the browser with a POST request to preview a quiz as the teacher. Only present when the user may grade
   * @type {string}
   * @memberof Quiz
   */
  previewUrl?: string;
  /**
   * whether the quiz has a published or unpublished draft state.
   * @type {boolean}
   * @memberof Quiz
   */
  published?: boolean;
  /**
   * the number of questions in the quiz
   * @type {number}
   * @memberof Quiz
   */
  questionCount?: number;
  /**
   * List of question types in the quiz
   * @type {Array<string>}
   * @memberof Quiz
   */
  questionTypes?: Array<string>;
  /**
   * Link to endpoint to send extensions for this quiz.
   * @type {string}
   * @memberof Quiz
   */
  quizExtensionsUrl?: string;
  /**
   * type of quiz possible values: 'practice_quiz', 'assignment', 'graded_survey', 'survey'
   * @type {string}
   * @memberof Quiz
   */
  quizType?: string;
  /**
   * which quiz score to keep (only if allowed_attempts != 1) possible values: 'keep_highest', 'keep_latest'
   * @type {string}
   * @memberof Quiz
   */
  scoringPolicy?: string;
  /**
   * show which answers were correct when results are shown? only valid if hide_results=null
   * @type {boolean}
   * @memberof Quiz
   */
  showCorrectAnswers?: boolean;
  /**
   * when should the correct answers be visible by students? only valid if show_correct_answers=true
   * @type {Date}
   * @memberof Quiz
   */
  showCorrectAnswersAt?: Date;
  /**
   * restrict the show_correct_answers option above to apply only to the last submitted attempt of a quiz that allows multiple attempts. only valid if show_correct_answers=true and allowed_attempts > 1
   * @type {boolean}
   * @memberof Quiz
   */
  showCorrectAnswersLastAttempt?: boolean;
  /**
   * shuffle answers for students?
   * @type {boolean}
   * @memberof Quiz
   */
  shuffleAnswers?: boolean;
  /**
   * Link to Speed Grader for this quiz. Will not be present if quiz is unpublished
   * @type {string}
   * @memberof Quiz
   */
  speedgraderUrl?: string;
  /**
   * quiz time limit in minutes
   * @type {number}
   * @memberof Quiz
   */
  timeLimit?: number;
  /**
   * the title of the quiz
   * @type {string}
   * @memberof Quiz
   */
  title?: string;
  /**
   * when to unlock the quiz
   * @type {Date}
   * @memberof Quiz
   */
  unlockAt?: Date;
  /**
   * Whether the assignment's 'published' state can be changed to false. Will be false if there are student submissions for the quiz.
   * @type {boolean}
   * @memberof Quiz
   */
  unpublishable?: boolean;
  /**
   * Current version number of the quiz
   * @type {number}
   * @memberof Quiz
   */
  versionNumber?: number;
}

/**
 * Check if a given object implements the Quiz interface.
 */
export function instanceOfQuiz(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function QuizFromJSON(json: any): Quiz {
  return QuizFromJSONTyped(json, false);
}

export function QuizFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Quiz {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessCode: !exists(json, "access_code") ? undefined : json["access_code"],
    allDates: !exists(json, "all_dates")
      ? undefined
      : (json["all_dates"] as Array<any>).map(AssignmentDateFromJSON),
    allowedAttempts: !exists(json, "allowed_attempts")
      ? undefined
      : json["allowed_attempts"],
    anonymousSubmissions: !exists(json, "anonymous_submissions")
      ? undefined
      : json["anonymous_submissions"],
    assignmentGroupId: !exists(json, "assignment_group_id")
      ? undefined
      : json["assignment_group_id"],
    cantGoBack: !exists(json, "cant_go_back")
      ? undefined
      : json["cant_go_back"],
    description: !exists(json, "description") ? undefined : json["description"],
    dueAt: !exists(json, "due_at") ? undefined : new Date(json["due_at"]),
    hideCorrectAnswersAt: !exists(json, "hide_correct_answers_at")
      ? undefined
      : new Date(json["hide_correct_answers_at"]),
    hideResults: !exists(json, "hide_results")
      ? undefined
      : json["hide_results"],
    htmlUrl: !exists(json, "html_url") ? undefined : json["html_url"],
    id: !exists(json, "id") ? undefined : json["id"],
    ipFilter: !exists(json, "ip_filter") ? undefined : json["ip_filter"],
    lockAt: !exists(json, "lock_at") ? undefined : new Date(json["lock_at"]),
    lockExplanation: !exists(json, "lock_explanation")
      ? undefined
      : json["lock_explanation"],
    lockInfo: !exists(json, "lock_info")
      ? undefined
      : LockInfoFromJSON(json["lock_info"]),
    lockedForUser: !exists(json, "locked_for_user")
      ? undefined
      : json["locked_for_user"],
    mobileUrl: !exists(json, "mobile_url") ? undefined : json["mobile_url"],
    oneQuestionAtATime: !exists(json, "one_question_at_a_time")
      ? undefined
      : json["one_question_at_a_time"],
    oneTimeResults: !exists(json, "one_time_results")
      ? undefined
      : json["one_time_results"],
    permissions: !exists(json, "permissions")
      ? undefined
      : QuizPermissionsFromJSON(json["permissions"]),
    pointsPossible: !exists(json, "points_possible")
      ? undefined
      : json["points_possible"],
    previewUrl: !exists(json, "preview_url") ? undefined : json["preview_url"],
    published: !exists(json, "published") ? undefined : json["published"],
    questionCount: !exists(json, "question_count")
      ? undefined
      : json["question_count"],
    questionTypes: !exists(json, "question_types")
      ? undefined
      : json["question_types"],
    quizExtensionsUrl: !exists(json, "quiz_extensions_url")
      ? undefined
      : json["quiz_extensions_url"],
    quizType: !exists(json, "quiz_type") ? undefined : json["quiz_type"],
    scoringPolicy: !exists(json, "scoring_policy")
      ? undefined
      : json["scoring_policy"],
    showCorrectAnswers: !exists(json, "show_correct_answers")
      ? undefined
      : json["show_correct_answers"],
    showCorrectAnswersAt: !exists(json, "show_correct_answers_at")
      ? undefined
      : new Date(json["show_correct_answers_at"]),
    showCorrectAnswersLastAttempt: !exists(
      json,
      "show_correct_answers_last_attempt"
    )
      ? undefined
      : json["show_correct_answers_last_attempt"],
    shuffleAnswers: !exists(json, "shuffle_answers")
      ? undefined
      : json["shuffle_answers"],
    speedgraderUrl: !exists(json, "speedgrader_url")
      ? undefined
      : json["speedgrader_url"],
    timeLimit: !exists(json, "time_limit") ? undefined : json["time_limit"],
    title: !exists(json, "title") ? undefined : json["title"],
    unlockAt: !exists(json, "unlock_at")
      ? undefined
      : new Date(json["unlock_at"]),
    unpublishable: !exists(json, "unpublishable")
      ? undefined
      : json["unpublishable"],
    versionNumber: !exists(json, "version_number")
      ? undefined
      : json["version_number"],
  };
}

export function QuizToJSON(value?: Quiz | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access_code: value.accessCode,
    all_dates:
      value.allDates === undefined
        ? undefined
        : (value.allDates as Array<any>).map(AssignmentDateToJSON),
    allowed_attempts: value.allowedAttempts,
    anonymous_submissions: value.anonymousSubmissions,
    assignment_group_id: value.assignmentGroupId,
    cant_go_back: value.cantGoBack,
    description: value.description,
    due_at: value.dueAt === undefined ? undefined : value.dueAt.toISOString(),
    hide_correct_answers_at:
      value.hideCorrectAnswersAt === undefined
        ? undefined
        : value.hideCorrectAnswersAt.toISOString(),
    hide_results: value.hideResults,
    html_url: value.htmlUrl,
    id: value.id,
    ip_filter: value.ipFilter,
    lock_at:
      value.lockAt === undefined ? undefined : value.lockAt.toISOString(),
    lock_explanation: value.lockExplanation,
    lock_info: LockInfoToJSON(value.lockInfo),
    locked_for_user: value.lockedForUser,
    mobile_url: value.mobileUrl,
    one_question_at_a_time: value.oneQuestionAtATime,
    one_time_results: value.oneTimeResults,
    permissions: QuizPermissionsToJSON(value.permissions),
    points_possible: value.pointsPossible,
    preview_url: value.previewUrl,
    published: value.published,
    question_count: value.questionCount,
    question_types: value.questionTypes,
    quiz_extensions_url: value.quizExtensionsUrl,
    quiz_type: value.quizType,
    scoring_policy: value.scoringPolicy,
    show_correct_answers: value.showCorrectAnswers,
    show_correct_answers_at:
      value.showCorrectAnswersAt === undefined
        ? undefined
        : value.showCorrectAnswersAt.toISOString(),
    show_correct_answers_last_attempt: value.showCorrectAnswersLastAttempt,
    shuffle_answers: value.shuffleAnswers,
    speedgrader_url: value.speedgraderUrl,
    time_limit: value.timeLimit,
    title: value.title,
    unlock_at:
      value.unlockAt === undefined ? undefined : value.unlockAt.toISOString(),
    unpublishable: value.unpublishable,
    version_number: value.versionNumber,
  };
}
