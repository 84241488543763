import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCourseMutation } from "../../../API";
import AiAssistantButton from "../../../components/AiAssistantButton";
import { withCourse } from "../../../components/withCourse";
import WizardPageTitle from "../../../components/WizardPageTitle";
import { Course } from "../../../graphql/API";
import { defaultPrompt, getConversationContext, suggestQuizzesSchema } from "./prompts";
import QuizAccordionList from "./QuizAccordionList";

function Quizzes({ course }: { course: Course }) {
  const { id, quizzes } = course;
  const navigate = useNavigate();
  const updateQuizMutation = useCourseMutation(id);

  // We have 2 of the same mutation because we want to show different "isLoading" states for adding a week vs updating a week
  const createQuizMutation = useCourseMutation(id);

  function addQuiz() {
    createQuizMutation.mutate({
      id,
      quizzes: [
        ...course.quizzes,
        {
          quizHeldInWeek: course.quizzes.length + 1,
          conceptsCoveredInTheQuiz: [],
          courseObjectivesCoveredByTheQuiz: [],
          quizQuestions: [
            {
              question: "<new question>",
              multipleChoiceAnswers: [
                "<answer option 1>",
                "<answer option 2>",
                "<answer option 3>",
                "<answer option 4>",
              ],
            },
          ],
        },
      ],
    });
  }

  return (
    <Box>
      <WizardPageTitle title="Quizzes" />
      <Typography variant="body1" gutterBottom>
        Let's design the quizzes for <em>{course.name}</em>.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We have specified that we want to have a quiz every{" "}
        {course.assessmentSettings.quizFrequencyInWeeks} week(s), and each quiz
        should have {course.assessmentSettings.quizNumberOfQuestions} questions.
      </Typography>
      <Stack direction="row" spacing={2} mt={2} mb={2}>
        <LoadingButton
          variant="contained"
          startIcon={<AddIcon />}
          color="info"
          onClick={addQuiz}
          loading={createQuizMutation.isLoading}
        >
          <span>Add</span>
        </LoadingButton>
        <AiAssistantButton
          conversationContext={getConversationContext(course)}
          defaultPrompt={defaultPrompt}
          functionsSchema={suggestQuizzesSchema(course)}
          onAssistantResponse={(data: any) => {
            updateQuizMutation.mutate({
              id,
              quizzes: data,
            });
          }}
        />
      </Stack>
      <QuizAccordionList
        value={quizzes}
        course={course} // Needed so the AI assistant has access to the course
        isLoading={updateQuizMutation.isLoading}
        onChange={(newQuizzes) => {
          return updateQuizMutation.mutateAsync({
            id,
            quizzes: [...newQuizzes],
          });
        }}
      />
      <Stack
        spacing={2}
        alignItems="start"
        justifyContent="space-around"
        mt={2}
      >
        <Button
          sx={{ mt: 2 }}
          variant="outlined"
          onClick={() => {
						navigate(`/course/${id}/step-7`);
          }}
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
}

export default withCourse(Quizzes);
