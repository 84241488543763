import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Grow,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import AiAssistantButton from "../../../components/AiAssistantButton";
import { Course, CourseObjective } from "../../../graphql/API";
import { updateCourseObjectiveSchema, getConversationContext } from "./prompts";

interface CourseObjectiveCardProps {
  value: CourseObjective;
  index: number;
  onUpdate: (value: CourseObjective) => Promise<void>;
  onDelete: () => void;
  onCardClick: Function;
  isSelected: boolean;
  isLoading: boolean;
  course: Course;
}

export function CourseObjectiveCard(props: CourseObjectiveCardProps) {
  const { value, index, onCardClick, isSelected, isLoading } = props;
  const [cardState, setCardState] = React.useState<"displaying" | "editing">(
    "displaying"
  );
  const [draftCourseObjective, setDraftCourseObjective] =
    React.useState<CourseObjective>(value);
  const cardReference = useRef(null);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          margin: "10px",
        }}
        ref={cardReference}
      >
        <Grow in={true}>
          {cardState === "displaying" ? (
            <CardActionArea onClick={() => onCardClick()}>
              <CardContent
                sx={{
                  border: isSelected
                    ? "3px solid lightblue"
                    : "3px solid white",
                }}
              >
                <Typography variant="body1">
                  Objective {index + 1}: {value.objectiveDescription}
                </Typography>
                <Typography variant="body2">
                  {value.objectiveReasoning}
                </Typography>
              </CardContent>
            </CardActionArea>
          ) : (
            // Editing
            <Stack spacing={2} p={3} alignItems="start">
              <Typography variant="body1">Objective {index + 1}</Typography>
              <TextField
                id="outlined-basic"
                multiline
                fullWidth
                label="Description"
                variant="outlined"
                value={draftCourseObjective.objectiveDescription}
                onChange={(event) => {
                  setDraftCourseObjective({
                    ...draftCourseObjective,
                    objectiveDescription: event.target.value,
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                multiline
                fullWidth
                label="Reasoning"
                variant="outlined"
                value={draftCourseObjective.objectiveReasoning}
                onChange={(event) => {
                  setDraftCourseObjective({
                    ...draftCourseObjective,
                    objectiveReasoning: event.target.value,
                  });
                }}
              />
              <LoadingButton
                variant="contained"
                startIcon={<DoneIcon />}
                loading={isLoading}
                onClick={async () => {
                  await props.onUpdate(draftCourseObjective);
                  setCardState("displaying");
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          )}
        </Grow>
      </Card>
      <Popper
        open={isSelected && cardState === "displaying"}
        anchorEl={cardReference.current}
        placement="bottom-end"
      >
        <Card variant="elevation">
          <Grid container spacing={2} p={1}>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                color="info"
                onClick={() => {
                  setCardState("editing");
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <AiAssistantButton
                conversationContext={getConversationContext(
                  props.course
                ).concat([
                  {
                    role: "user",
                    content: `I'm currently editing this course objective, given here as a JSON object in triple quotes """${JSON.stringify(
                      value
                    )}"""`,
                  },
                  {
                    role: "assistant",
                    content: `Ok, how would you like to change this objective?`,
                  },
                ])}
                functionsSchema={updateCourseObjectiveSchema}
                placeholder={"How would you like to change this objective?"}
                onAssistantResponse={async (data: any) => {
                  await props.onUpdate(data);
                  setCardState("displaying");
                }}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                startIcon={<DeleteIcon />}
                color="error"
                loading={isLoading}
                onClick={() => {
                  props.onDelete();
                }}
              >
                Delete
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </Popper>
    </>
  );
}
