import { Box } from "@mui/material";
import { useState } from "react";
import { Course, Quiz } from "../../../graphql/API";
import { QuizAccordion } from "./QuizAccordion";

interface QuizAccordionListProps {
  value: Quiz[];
  onChange: (value: Quiz[]) => Promise<void>;
  isLoading: boolean;
  course: Course;
}

export function QuizAccordionList(props: QuizAccordionListProps) {
  const [selectedElement, setSelectedElement] = useState<string | null>(null);

  return (
    <Box>
      {props.value.map((quiz, index) => (
        <QuizAccordion
          value={quiz}
          key={JSON.stringify(quiz)}
          onUpdate={(value) => {
            const newQuizzes = [...props.value];
            newQuizzes[index] = value;
            setSelectedElement(null);
            return props.onChange(newQuizzes);
          }}
          onDelete={() => {
            const newQuizzes = [...props.value];
            newQuizzes.splice(index, 1);
            setSelectedElement(null);
            return props.onChange(newQuizzes);
          }}
          index={index}
          isSelected={selectedElement === `quiz-${index}`}
          onAccordionChange={(isExpanded) => {
            if (isExpanded) {
              setSelectedElement(`quiz-${index}`);
            } else {
              setSelectedElement(null);
            }
          }}
          isLoading={props.isLoading}
          course={props.course}
        />
      ))}
    </Box>
  );
}

export default QuizAccordionList;
