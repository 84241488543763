import { createCanvasClient } from "../../../API";
import Canvas from "../../../canvas-api";
import { Answer } from "../../../canvas-api/generated-code/models";
import { Course } from "../../../graphql/API";

export async function exportCourseToCanvas(course: Course) {
  const canvas_client = await createCanvasClient();

  if (course.name === undefined || course.name === null) {
    throw new Error("Course name is undefined");
  }

  const newCourseRequest: Canvas.CreateNewCourseRequest = {
    accountId: "1", // accountId
    courseName: course.name,
    enrollMe: true,
  };
  const createdCourse = await canvas_client.createNewCourse(newCourseRequest);

  // Create modules
  await Promise.all(
    course.weeks.map(async (week, index) => {
      const weekNumber = index + 1;

      // Create module
      const createdModule = await canvas_client.createModule({
        courseId: `${createdCourse.id}`,
        moduleName: week.weekTitle,
        modulePosition: weekNumber,
      });

      // Create pages for the module
      const outline = await canvas_client.createPageCourses({
        courseId: `${createdCourse.id}`,
        wikiPageTitle: `Week ${weekNumber} Outline`,
        wikiPageBody: `<h1>${week.weekTitle}</h1><p>${week.weekReasoning}</p>`,
      });
      await canvas_client.createModuleItem({
        courseId: `${createdCourse.id}`,
        moduleId: `${createdModule.id}`,
        moduleItemType: "Page",
        moduleItemPageUrl: outline.url,
      });
      const learningActivities = await canvas_client.createPageCourses({
        courseId: `${createdCourse.id}`,
        wikiPageTitle: `Week ${weekNumber} Suggested Learning Activities`,
        wikiPageBody: `<h1>${
          week.weekTitle
        }</h1><p>${week.suggestedLearningActivities.join("<br/>")}</p>`,
      });
      await canvas_client.createModuleItem({
        courseId: `${createdCourse.id}`,
        moduleId: `${createdModule.id}`,
        moduleItemType: "Page",
        moduleItemPageUrl: learningActivities.url,
      });

      return;
    })
  );

  // Create the quizzes
  await Promise.all(
    course.quizzes.map(async (quiz, index) => {
      const createdQuiz = await canvas_client.createQuiz({
        courseId: `${createdCourse.id}`,
        quizTitle: `Quiz ${index + 1}`,
      });
      // Create the questions
      quiz.quizQuestions.forEach(async (question) => {
        const createdQuestion: Canvas.CreateSingleQuizQuestionRequest = {
          courseId: `${createdCourse.id}`,
          quizId: `${createdQuiz.id}`,
          questionQuestionText: question.question,
          questionQuestionType: "multiple_choice_question",
          questionAnswers: JSON.stringify(
            question.multipleChoiceAnswers.map(
              (answer) =>
                ({
                  answerText: answer,
                  answerWeight: 0,
                } as Answer)
            )
          ),
        };
        await canvas_client.createSingleQuizQuestion(createdQuestion);
      });
    })
  );
}
